import React, { useState } from 'react';
import tw, { css, styled, theme } from 'twin.macro';
import {
  ThumbsUp,
  ShareNetwork,
  ChatDots,
  GlobeHemisphereWest,
} from '@phosphor-icons/react';
import SymplLogo from 'assets/sympl-logo-cropped.png';
import { AdEditorPreviewProps } from 'components/ad-builder/Preview';
import { AdEditorStencilCanvas as AdEditorStencil } from 'components/ad-builder/StencilCanvas';
import EditableInput from 'components/form/editable-input/EditableInput';
import AdEditorVideoContainer from 'components/ad-builder/VideoContainer';
import { placeholderMock as placeholder } from 'mocks/advertising';
import Img from 'components/image/Image';

import useAdEditorContext from 'hooks/context/ad-editor-context';
import AdEditorTextArea from 'components/ad-builder/ad-editor-textarea/TextArea';
import {
  AdInteractionButton,
  AdUserInteraction,
  AdUserInteractionsWrapper,
} from 'components/ad-builder/LikeCommentShareBar';
import { AdCreativeType } from 'types/adTypes';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import { CTALabel } from 'data/adEditorCTA';
import useNavigationContext from 'hooks/context/nav-context';

export const FeedPreview: React.FC<AdEditorPreviewProps> = ({ id }) => {
  const { currentVariant, setActiveTab, updateCurrentVariant } =
    useAdEditorContext();

  const { currentVacancy } = useNavigationContext();
  const [ctaError, setCtaError] = useState<string>('');

  return (
    <>
      <AdContainer>
        <AdHeader id={id}>
          <AdSymplLogoWrapper>
            <Img
              src={currentVacancy?.brand?.facebookPage?.logo_url ?? SymplLogo}
              alt="logo"
              tw="bg-cover bg-center h-full w-full object-cover"
            />
          </AdSymplLogoWrapper>
          <div tw="items-start justify-start w-auto grow mr-2 h-10">
            <AdCompanyName>
              {currentVacancy?.brand?.facebookPage?.name ?? 'sympl'}
            </AdCompanyName>
            <div tw="flex items-center">
              <AdSponsoredText>Sponsored &middot;</AdSponsoredText>
              <GlobeHemisphereWest size={16} weight="bold" color={'#6b7280'} />
            </div>
          </div>
          <div tw="flex w-5 justify-evenly items-center">
            {[...Array(3)].map((_value, index) => (
              <span key={index} tw="bg-gray-500 rounded-full h-1 w-1 block" />
            ))}
          </div>
        </AdHeader>

        <AdEditorTextArea />

        {currentVariant?.creative_type === AdCreativeType.VIDEO ? (
          <AdEditorVideoContainer />
        ) : (
          <AdEditorStencil />
        )}

        <AdCtaContainer>
          {/* error text here */}
          {ctaError && (
            <div tw="absolute right-2 -mt-3">
              <p tw="text-red-400 text-xs">{ctaError}</p>
            </div>
          )}
          <AdCtaTextWrapper>
            <EditableInput
              key={currentVariant?.display_link ?? 'display_link'}
              id="display_link"
              defaultValue={currentVariant?.display_link}
              placeholder={placeholder.display_link}
              onChange={(value) => updateCurrentVariant('display_link', value)}
              onError={() => {
                updateCurrentVariant('display_link', '');
                setCtaError('this link is invalid, please try again');
                setTimeout(() => setCtaError(''), 3000);
              }}
              editorRows={2}
              style={{ color: theme`colors.gray.400` }}
              validationRegex={
                /(?:https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
              }
            >
              {currentVariant?.display_link}
            </EditableInput>
            <EditableInput
              key={currentVariant?.headline ?? 'headline'}
              id="cta-title"
              defaultValue={currentVariant?.headline}
              placeholder={placeholder.headline}
              onChange={(value) => updateCurrentVariant('headline', value)}
              editorRows={2}
            >
              {currentVariant?.headline}
            </EditableInput>
            <EditableInput
              id="description"
              key={currentVariant?.description ?? 'description'}
              defaultValue={currentVariant?.description}
              placeholder={placeholder.description}
              onChange={(value) => updateCurrentVariant('description', value)}
              editorRows={2}
              style={{ color: theme`colors.gray.400` }}
            >
              {currentVariant?.description}
            </EditableInput>
          </AdCtaTextWrapper>
          <AdCtaButtonWrapper
            onClick={() => setActiveTab?.(AdEditorSideBarTabs.BANNER_OPTIONS)}
          >
            <AdCtaButton>{currentVariant?.cta || CTALabel.SIGN_UP}</AdCtaButton>
          </AdCtaButtonWrapper>
        </AdCtaContainer>
        <AdUserInteractionsWrapper>
          <AdUserInteraction>
            <AdInteractionButton>
              <ThumbsUp width={18} height={18} weight="bold" />
              <span tw="hidden md:(block ml-1.5)">Like</span>
            </AdInteractionButton>
          </AdUserInteraction>
          <AdUserInteraction>
            <AdInteractionButton>
              <ChatDots width={18} height={18} weight="bold" />
              <span tw="hidden md:(block ml-1.5)">Comment</span>
            </AdInteractionButton>
          </AdUserInteraction>
          <AdUserInteraction>
            <AdInteractionButton>
              <ShareNetwork width={18} height={18} weight="bold" />
              <span tw="hidden md:(block ml-1.5)">Share</span>
            </AdInteractionButton>
          </AdUserInteraction>
        </AdUserInteractionsWrapper>
      </AdContainer>
    </>
  );
};

const AdContainer = styled.div`
  ${tw` bg-white w-auto h-auto min-w-[23rem] rounded-lg overflow-hidden shadow-md sm:max-w-[25rem] max-[400px]:(w-[95vw])`}
  ${css`
    #cta-title {
      font-size: 1em;
      font-weight: 600;
      color: ${theme`colors.gray.700`};
    }

    #description {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      overflow-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    #description:focus {
      white-space: normal;
    }
  `}
`;

const AdHeader = styled.header`
  ${tw`flex flex-row p-4 pb-0 items-center justify-between leading-snug mb-3`}
`;

const AdSymplLogoWrapper = styled.div`
  ${tw`overflow-hidden rounded-full h-10 w-10 border border-gray-300 mr-2`}
`;

const AdCompanyName = styled.p`
  ${tw`text-sm font-semibold`}
`;

const AdSponsoredText = styled.p`
  ${tw`text-gray-500 mr-0.5 text-xs`}
`;

const AdCtaContainer = styled.div`
  ${tw`grid grid-cols-4 p-3 justify-between max-w-full bg-gray-100 border-b border-gray-300`}
`;

const AdCtaTextWrapper = styled.div`
  ${tw`grow flex flex-col basis-0	max-w-[90%] shrink flex-nowrap text-gray-500 col-start-1 col-end-5`}
`;

const AdCtaButtonWrapper = styled.div`
  ${tw`flex justify-center items-center col-start-5 col-end-6`}
`;

const AdCtaButton = styled.button`
  ${tw`
    rounded-md text-sm h-9 px-4 bg-gray-200 font-semibold w-auto transition cursor-pointer
    hover:(ring-2 ring-indigo-400 bg-indigo-50 rounded-sm)
  `}
`;

export default FeedPreview;
