import { Feedback, FeedbackStatus } from 'components/feedback/FeedbackWidget';
import { VacancyPromptPayload } from 'utils/vacancy-generator/vacancyHelpers';
import { AdChannel, Metrics } from './adTypes';
import { CustomerResource } from './apiTypes';
import { WordPressIconItem } from './wordpressTypes';
import { Brand } from './customer/types';

export type Vacancy = {
  key: number;
  label: string;
  title: string;
  published: boolean;
  status: VacancyStatus;
  spend_status: VacancySpendStatus;
  customer_id: number;
  brand_id: number;
  company: string;
  is_booster: boolean;
  budget: number;
  customer_type?: string;
  created_at: string;
  has_custom_url: boolean;
  language_id: number;
  templates: number[];
  brand?: Brand;
  channels: AdChannel[];
  location?: string;
  vacCopy?: VacancyCopy;
  feedback_status?: FeedbackStatus;
  previous_feedback?: Feedback[];
};

export enum VacancyStatus {
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum VacancySpendStatus {
  NONE = 'NONE',
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type VacancyMetric = {
  customer_type?: string;
  status: VacancyStatus;
  spend_status: VacancySpendStatus;
  vacBudget?: VacancyBudget | null;
  vacSpent?: number | null;
  vacApplications?: number | null;
  vacMetrics?: Metrics[];
};

export type VacancyBudget = {
  total_budget: number | null;
};

export interface VacancyContextType {
  vacancyData?: VacancyCopy;
  setVacancyData: React.Dispatch<React.SetStateAction<VacancyCopy | undefined>>;
  saveHandler: (vacancyData?: VacancyCopy) => Promise<void>;
  saveCustomUrlHandler: (customUrl: string, cb?: () => void) => Promise<void>;
  dummyTxt: string;
  vacancyLoading: boolean;
  refetchCopy: () => void;
  generatingFields: (keyof VacancyCopy)[];
  setGeneratingFields: React.Dispatch<
    React.SetStateAction<(keyof VacancyCopy)[]>
  >;
  payload?: VacancyPromptPayload;
  setPayload: React.Dispatch<
    React.SetStateAction<VacancyPromptPayload | undefined>
  >;
}

export type VacancyCopyKeyname =
  | 'vac_text_uniqueness'
  | 'vac_text_essence'
  | 'procedure_text'
  | 'vac_text_todo'
  | 'vac_text_expectations'
  | 'vac_text_offer'
  | 'vac_text_company';

export type VacancyCopy = {
  id?: number;
  brand_id?: number;
  vac_url?: string;
  share_url?: string;
  url?: string;
  has_custom_url?: boolean;
  published: boolean;
  // Theming
  primary_color: string;
  secondary_color: string;
  tertiary_color: string;
  // Resources
  pictures?: {
    logo?: CustomerResource | null;
    image_cb1?: CustomerResource | null;
    image_cb3?: CustomerResource | null;
  } | null;
  // Titles
  title?: string;
  procedure_title?: string;
  todo_title?: string;
  expectations_title?: string;
  offer_title?: string;
  company_title?: string;
  page_cta?: string;
  // Lists
  vac_text_uniqueness?: WordPressIconItem[];
  /** HTML-string */
  vac_text_essence?: string;
  /** HTML-string */
  procedure_text?: string;
  /** HTML-string */
  vac_text_todo?: string;
  /** HTML-string */
  vac_text_expectations?: string;
  /** HTML-string */
  vac_text_offer?: string;
  /** HTML-string */
  vac_text_company?: string;
  feedback_status?: FeedbackStatus;
  previous_feedback?: Feedback[];
};

export interface UpdateVacancyCopy {
  vacancyId: number;
  input: {
    id?: number;
    vac_url?: string;
    // Theming
    primary_color: string;
    secondary_color: string;
    tertiary_color: string;
    // Resources
    pictures: {
      logo: number | null;
      image_cb1: number | null;
      image_cb3: number | null;
    } | null;
    // Titles
    title?: string;
    procedure_title?: string;
    todo_title?: string;
    expectations_title?: string;
    offer_title?: string;
    company_title?: string;
    page_cta?: string;
    // Lists
    vac_text_uniqueness?: string;
    /** HTML-string */
    vac_text_essence?: string;
    /** HTML-string */
    procedure_text?: string;
    /** HTML-string */
    vac_text_todo?: string;
    /** HTML-string */
    vac_text_expectations?: string;
    /** HTML-string */
    vac_text_offer?: string;
    /** HTML-string */
    vac_text_company?: string;
    feedback_status?: FeedbackStatus;
    previous_feedback?: Feedback[];
  };
}
