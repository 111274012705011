import React, { useMemo, useState } from 'react';
import useMetaQuery from 'hooks/metaQuery';
import { GET_TRANSACTIONS_PAGINATED } from 'graphql/customers/queries';
import AutoPagination from 'components/table/auto-table/AutoPagination';
import { Meta } from 'types/apiTypes';
import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import AutoTable from 'components/table/auto-table/AutoTable';
import useNavigationContext from 'hooks/context/nav-context';
import { v4 as uuid } from 'uuid';
import 'twin.macro';
import tw from 'twin.macro';
import dayjs from 'dayjs';
import { ArrowRight } from '@phosphor-icons/react';
import InformationToolTip from 'components/tooltip/InformationToolTip';

type Transaction = {
  key?: string;
  type?: string;
  from_type: string;
  from_id: number;
  from_name?: string;
  to_type: string;
  to_id: number;
  to_name?: string;
  amount: number;
  created_by_name: string;
  created_at: string;
};

interface TransactionsResponse {
  transactions: Transaction[];
  meta: Meta;
}

const COLUMNS = ['Date', 'From', '', 'To', 'Initiated by', 'Amount'];
const TYPE_CUSTOMER = 'App\\Sympl\\Models\\Customer';
const TOOLTIP_REASONS = ['overspend_correction', 'remaining_vacancy_budget'];

const TransactionsTable: React.FC = () => {
  const { activeCustomer } = useNavigationContext();

  const [activePage, setActivePage] = useState(1);

  const { loading, data, meta, fetchMore } = useMetaQuery<
    TransactionsResponse,
    {
      pageSize: number;
      currentPage: number;
      customerId?: number;
    }
  >(GET_TRANSACTIONS_PAGINATED, {
    fetchPolicy: 'network-only',
    variables: {
      pageSize: 20,
      currentPage: activePage ?? 1,
      customerId: activeCustomer,
    },
  });

  const handlePageChange = async (currentPage: number) => {
    setActivePage(currentPage);
    try {
      await fetchMore({
        variables: {
          pageSize: 20,
          currentPage,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        },
      });
    } catch (_) {
      return Promise.reject();
    } finally {
      return Promise.resolve();
    }
  };

  const paginationMeta: Meta['pagination'] = useMemo(() => {
    return meta?.pagination;
  }, [meta]);

  const getField = (type: string, name?: string) => {
    if (type === TYPE_CUSTOMER) {
      return (
        <p tw="py-1 text-indigo-700 rounded-full inline-block w-48">{name}</p>
      );
    }
    return (
      <p tw="py-1 text-stone-700 rounded-full inline-block w-48">{name}</p>
    );
  };

  const formatDate = (dateString: string) => {
    const date = dayjs(dateString);
    const formatString =
      dayjs().year() === date.year() ? 'MMM D' : 'MMM D, YYYY';
    return date.format(formatString);
  };

  const getToolTipMessage = (type: string, amount?: number) => {
    switch (type) {
      case 'overspend_correction': // overspend = more money spent than budgeted
        return `The campaign spent €${amount} more than budgeted, we've corrected this using your balance`;
      case 'remaining_vacancy_budget': // underspend = less money spent than budgeted
        return `There was €${amount} left on this campaign. It has been transferred back to your balance`;
      default:
        return '';
    }
  };

  const rows = useMemo(() => {
    if (!data?.transactions) return [];
    return data?.transactions.map((transaction) => {
      transaction.key = uuid();
      const tooltipIsNeeded = TOOLTIP_REASONS.includes(
        transaction.type as string
      );
      return [
        // date
        <p>{formatDate(transaction.created_at)}</p>,

        // from
        <>{getField(transaction.from_type, transaction.from_name)}</>,

        <ArrowRight />,

        // to
        <>{getField(transaction.to_type, transaction.to_name)}</>,

        // user
        <p tw="flex items-center gap-1">
          {transaction.created_by_name}
          {tooltipIsNeeded && (
            <InformationToolTip
              icon="information"
              name="transaction_user_tooltip"
              text={getToolTipMessage(
                transaction.type as string,
                transaction.amount
              )}
            />
          )}
        </p>,

        // amount
        <div tw="[white-space: nowrap]">
          <p tw="font-medium">
            €
            {transaction.amount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </div>,
      ];
    });
  }, [data]);

  return loading ? (
    <div>
      <SkeletonList />
    </div>
  ) : (
    <div>
      <AutoTable columns={COLUMNS} rows={rows} headerStyling={tw`pl-6`} />
      <AutoPagination
        selectedPage={activePage}
        itemsLoaded={rows?.length}
        totalItems={paginationMeta?.total}
        totalPages={paginationMeta?.total_pages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default TransactionsTable;
