import tw, { styled } from 'twin.macro';
import AdEditorUploadForm from 'components/ad-builder/UploadForm';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import React, { useState } from 'react';
import { AdEditorSideBarTabs } from './AdBuilder';
import Button from 'components/button/Button';
import AdEditorImageCropper from 'components/ad-builder/ad-editor-cropper/Cropper';
import Dropdown from 'components/dropdown/Dropdown';
import ColorPicker from 'components/form/ColorPicker';
import Title from 'components/title/Title';
import { Subhead } from 'components/typography/Typography';
import fontFamilies from 'data/fonts';
import {
  X,
  TextAlignLeft,
  TextAlignCenter,
  TextAlignRight,
} from '@phosphor-icons/react';
import { Crop } from 'react-image-crop';
import { AdCreativeType, AdPlacement } from 'types/adTypes';
import { getCanvasHeight, getCanvasWidth } from 'utils/adEditorCanvasHelpers';
import Input from 'components/form/input/Input';
import { ResourcesModalProps } from 'components/resources-modal/ResourcesModal';
import { PlacementMenu } from '../../components/ad-builder/PlacementMenu';
import { CTALabel, ctaLabels } from 'data/adEditorCTA';

const Sidebar = () => {
  const {
    activeTab,
    currentVariant,
    uploadMode: showResourcesModal,
    setUploadMode: setShowResourcesModal,
    updateCurrentVariant,
  } = useAdEditorContext();

  const creativeType = currentVariant?.creative_type;
  const path = currentVariant?.path?.path;
  const logo = currentVariant?.logo?.path;

  const [uploadMode, setUploadMode] = useState<ResourcesModalProps['mode']>();

  const textAlign = currentVariant?.currentObject?.textAlign;

  return !!activeTab ? (
    <>
      <AdEditorUploadForm
        show={showResourcesModal}
        mode={uploadMode}
        onClose={() => {
          setShowResourcesModal(false);
          setUploadMode(undefined);
        }}
      />

      <div tw="w-full p-5 xl:w-[350px] h-full max-h-[85vh] overflow-y-auto no-scrollbar">
        {activeTab === AdEditorSideBarTabs.VISUAL_OPTIONS && (
          <div tw="mx-auto">
            <div tw="mb-8 flex flex-col overflow-x-hidden">
              <div tw="flex flex-col justify-center items-center w-full gap-2">
                <div tw="flex w-full flex-wrap gap-1 p-2">
                  <div tw="flex-1">
                    <Button
                      variant={logo ? 'success' : 'primary'}
                      onClick={() => {
                        setUploadMode('logo');
                        setShowResourcesModal(true);
                      }}
                      disabled={
                        currentVariant?.creative_type === 'video' ||
                        !currentVariant?.path
                      }
                      stretch
                    >
                      {logo ? 'Change logo' : 'Choose a logo'}
                    </Button>
                  </div>
                  {logo && (
                    <Button
                      variant="inverted"
                      onClick={() => updateCurrentVariant('logo', null)}
                      disabled={currentVariant?.creative_type === 'video'}
                    >
                      <X weight="bold" tw="text-gray-600" />
                    </Button>
                  )}
                </div>
                <div tw="flex w-full flex-wrap gap-1 p-2">
                  <div tw="flex-1">
                    <Button
                      variant={path ? 'success' : 'primary'}
                      onClick={() => {
                        setUploadMode(
                          currentVariant?.placement === AdPlacement.REELS
                            ? 'video'
                            : 'visual'
                        );
                        setShowResourcesModal(true);
                      }}
                      stretch
                    >
                      {path
                        ? 'Change image/video'
                        : currentVariant?.placement === AdPlacement.REELS
                        ? 'Choose a video'
                        : 'Choose an image/video'}
                    </Button>
                  </div>
                  {path && (
                    <Button
                      variant="inverted"
                      onClick={() => updateCurrentVariant('path', null)}
                    >
                      <X weight="bold" tw="text-gray-600" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {path && creativeType === AdCreativeType.IMAGE && (
              <>
                <div tw="mb-3 border-b-2 border-gray-100">
                  <Title>Resize</Title>
                </div>
                <div tw="mt-3">
                  <AdEditorImageCropper
                    image={path}
                    crop={currentVariant?.crop || null}
                    onCrop={(crop: Crop | null) =>
                      updateCurrentVariant('crop', crop)
                    }
                    aspect={
                      currentVariant?.placement
                        ? getCanvasWidth(currentVariant.placement) /
                          getCanvasHeight(currentVariant.placement)
                        : 1
                    }
                  />
                </div>
              </>
            )}
          </div>
        )}
        {activeTab === AdEditorSideBarTabs.OVERLAY_OPTIONS && <PlacementMenu />}
        {activeTab === AdEditorSideBarTabs.TEXT_OPTIONS && (
          <div tw="flex flex-col gap-4">
            <Title>Text</Title>
            <div tw="flex items-center gap-2">
              <div tw="flex-1">
                <Input
                  value={currentVariant?.currentObject?.value ?? 'TEXT'}
                  onChange={({ target: { value } }) =>
                    updateCurrentVariant('currentObject', {
                      ...currentVariant?.currentObject,
                      value: value,
                    })
                  }
                />
              </div>
              <ColorPicker
                value={currentVariant?.currentObject?.color ?? '#000'}
                ariaInvalid={false}
                format="rgb"
                onChange={(color: string) =>
                  updateCurrentVariant('currentObject', {
                    ...currentVariant?.currentObject,
                    color: color,
                  })
                }
              />
            </div>
            <div tw="flex items-center gap-4">
              <div tw="flex flex-col space-y-2 w-1/2">
                <Subhead>Font family</Subhead>
                <Dropdown
                  label={currentVariant?.currentObject?.fontFamily || 'Arial'}
                  value={currentVariant?.currentObject?.fontFamily || 'Arial'}
                  items={fontFamilies.map((family) => {
                    return {
                      ...family,
                      customStyle: { fontFamily: family.key },
                    };
                  })}
                  onChange={(event) =>
                    updateCurrentVariant('currentObject', {
                      ...currentVariant?.currentObject,
                      fontFamily: event as string,
                    })
                  }
                />
              </div>
              <div tw="flex flex-col space-y-2 w-1/2">
                <Subhead>Font size</Subhead>
                <Input
                  type="number"
                  value={currentVariant?.currentObject?.fontSize ?? ''}
                  onChange={({ target: { value } }) => {
                    updateCurrentVariant('currentObject', {
                      ...currentVariant?.currentObject,
                      fontSize: isNaN(parseInt(value))
                        ? undefined
                        : parseInt(value),
                    });
                  }}
                />
              </div>
            </div>
            {/** TODO: re-enable after fix */}
            {false && (
              <div>
                <Subhead>Text alignment</Subhead>
                <div tw="flex flex-row gap-1 mt-2">
                  <TextAlignButton
                    active={textAlign === 'left'}
                    onClick={() =>
                      updateCurrentVariant('currentObject', {
                        ...currentVariant?.currentObject,
                        textAlign: 'left',
                      })
                    }
                  >
                    <TextAlignLeft weight="bold" size={20} />
                  </TextAlignButton>
                  <TextAlignButton
                    active={textAlign === 'center'}
                    onClick={() =>
                      updateCurrentVariant('currentObject', {
                        ...currentVariant?.currentObject,
                        textAlign: 'center',
                      })
                    }
                  >
                    <TextAlignCenter weight="bold" size={20} />
                  </TextAlignButton>
                  <TextAlignButton
                    active={textAlign === 'right'}
                    onClick={() =>
                      updateCurrentVariant('currentObject', {
                        ...currentVariant?.currentObject,
                        textAlign: 'right',
                      })
                    }
                  >
                    <TextAlignRight weight="bold" size={20} />
                  </TextAlignButton>
                </div>
              </div>
            )}
            <div tw="flex h-full flex-col gap-4 w-full mt-4">
              <Button
                onClick={() => {
                  updateCurrentVariant('currentObject', {
                    ...currentVariant?.currentObject,
                    isDeleted: true,
                  });
                }}
                variant="danger"
                stretch
              >
                Remove object
              </Button>
            </div>
          </div>
        )}
        {activeTab === AdEditorSideBarTabs.SHAPE_OPTIONS && (
          <div tw="flex h-full flex-col gap-4">
            <Title>Background color</Title>
            <div tw="flex w-full flex-row gap-2">
              <ColorPicker
                align="left"
                format="rgb"
                value={
                  currentVariant?.currentObject?.backgroundColor ?? '#ffffff'
                }
                variant="button"
                ariaInvalid={false}
                onChange={(color: string) =>
                  updateCurrentVariant('currentObject', {
                    ...currentVariant?.currentObject,
                    backgroundColor: color,
                  })
                }
                tw="flex-1"
              />
              {![0, 'NaN'].includes(
                parseFloat(
                  currentVariant?.currentObject?.backgroundColor?.split(
                    ','
                  )[3] ?? ''
                )
              ) && (
                <Button
                  customStyle={tw`flex-auto w-full`}
                  onClick={() => {
                    updateCurrentVariant('currentObject', {
                      ...currentVariant?.currentObject,
                      backgroundColor: 'rgb(0,0,0,0)',
                    });
                  }}
                  variant="inverted"
                >
                  <p tw="text-center w-full">Remove background</p>
                </Button>
              )}
            </div>
            <Button
              onClick={() => {
                updateCurrentVariant('currentObject', {
                  ...currentVariant?.currentObject,
                  isDeleted: true,
                });
              }}
              variant={'danger'}
              tw="w-full"
            >
              <p tw={'text-center w-full'}>Remove object</p>
            </Button>
          </div>
        )}
        {activeTab === AdEditorSideBarTabs.BANNER_OPTIONS && (
          <div tw="flex h-full flex-col gap-4">
            <Title>{'CTA (call to action)'}</Title>
            <div tw="flex items-center gap-4">
              <div tw="flex flex-col space-y-2 w-1/2">
                <Dropdown
                  label={currentVariant?.cta || CTALabel.SIGN_UP}
                  value={currentVariant?.cta || CTALabel.SIGN_UP}
                  items={ctaLabels}
                  onChange={(value) =>
                    updateCurrentVariant(
                      'cta',
                      CTALabel[value as keyof typeof CTALabel]
                    )
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

const TextAlignButton = styled.button<{ active: boolean }>`
  ${tw`p-2 rounded-md bg-gray-100`}

  ${({ active }) => active && tw`bg-gray-600 text-white`}
`;

export default Sidebar;
