import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getLocalAppSession, setLocalAppSession } from 'utils/storageHelpers';
import { useQuery } from 'hooks/sympl-query';
import { GET_VACANCY } from 'graphql/vacancies/queries';
import { ApolloQueryResult } from '@apollo/client';
import { GET_SESSION_FLAGS } from 'graphql/auth/queries';
import { updateData } from 'utils/intercomHelper';
import { Routes } from 'types/routeTypes';
import { Vacancy, VacancySpendStatus } from 'types/vacancyTypes';
import { Brand } from 'types/customer/types';
import { GET_CUSTOMER } from 'graphql/customers/queries';
import { Customer } from 'types/customer/types';
import { Subscription } from 'types/subscriptions/types';
import { GET_BRANDS } from 'graphql/brands/queries';
import { useMutation } from 'hooks/sympl-mutation';
import { UPDATE_LAST_USED_CUSTOMER } from 'graphql/users/mutations';
import { useNavigate } from 'react-router-dom';

export type NavigationContextType = {
  activeVacancy?: number;
  currentVacancy?: Vacancy;
  resetContext: () => void;
  setActiveVacancy: (config: ActiveVacancySetterConfig) => void;
  unsetActiveVacancy: () => void;
  activeCustomer?: number;
  setActiveCustomer: (customerId?: number) => void;
  vacIsPublished: boolean;
  vacIsBooster: boolean;
  vacSpendEnabled: boolean;
  isAdmin: boolean;
  jobPosition: string;
  refetchCurrentVacancy: () => void;
  hasIntakes: boolean;
  hasIntegrationsEnabled: boolean;
  hasVacancies: boolean;
  hasPublishedVacancy: boolean;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  prevLocation: string;
  setPrevLocation: (location: string) => void;
  clearResourceSelection: () => void;
  setClearResourceSelection: Dispatch<SetStateAction<() => void>>;
  customerColors: string[];
  customer: Customer | null; // TODO: can we drop null?
  subscription: Subscription | null;
  hasReachedVacsLimit: boolean;
  refetchCustomer: () => Promise<ApolloQueryResult<{ customer: Customer }>>;
  hasSymplVacancyPage: boolean;
  brands: Brand[];
  refetchBrands: () => void;
  vacancyLoading: boolean;
};

export interface VacancyData {
  vacancies: Vacancy[];
}

export interface SessionFlagsResponse {
  flags?: {
    user: {
      job_position?: string;
      is_sympl?: boolean;
    };
  };
}

export interface ActiveVacancySetterConfig {
  vacancyId: number;
  customerId?: number;
}

export const NavigationContext = createContext<NavigationContextType>(
  undefined!
);

export const NavigationProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [activeVacancy, _setActiveVacancy] = useState(
    getLocalAppSession()?.activeVacancy
  );

  const [activeCustomer, _setActiveCustomer] = useState(
    getLocalAppSession()?.activeCustomer
  );

  const [clearResourceSelection, setClearResourceSelection] = useState<
    () => void
  >(() => {});

  const navigate = useNavigate();

  const {
    refetch: refetchCurrentVacancy,
    data: vacancyData,
    loading: vacancyLoading,
  } = useQuery<{ vacancy: Vacancy }, { id: number; include: string }>(
    GET_VACANCY,
    {
      skip: !activeCustomer || !activeVacancy,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        id: activeVacancy as number,
        include: 'brand.facebookPage',
      },
      onCompleted: (data) => {
        setActiveVacancy({
          vacancyId: data.vacancy.key,
          customerId: data.vacancy.customer_id,
        });
      },
    }
  );

  const { data: brandData, refetch: refetchBrands } = useQuery<
    { brands: Brand[] },
    {}
  >(GET_BRANDS, {
    skip: !activeCustomer,
    fetchPolicy: 'network-only',
  });

  const brands = useMemo(() => brandData?.brands ?? [], [brandData]);

  const [updateLastUsedCustomer] = useMutation(UPDATE_LAST_USED_CUSTOMER);

  const currentVacancy = useMemo(() => vacancyData?.vacancy, [vacancyData]);

  const { data: sessionData } = useQuery<SessionFlagsResponse>(
    GET_SESSION_FLAGS,
    {
      skip: !activeCustomer,
    }
  );

  const { data: customerData, refetch: refetchCustomer } = useQuery<
    { customer: Customer },
    { customerId: number }
  >(GET_CUSTOMER, {
    skip: !activeCustomer,
    variables: { customerId: activeCustomer as number },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!activeVacancy && data.customer.last_vacancy_id)
        setActiveVacancy({
          vacancyId: data.customer.last_vacancy_id,
          customerId: data.customer.id,
        });
    },
  });

  const isAdmin = useMemo(
    () =>
      import.meta.env.VITE_ADMIN_ENABLED === 'true' &&
      !!sessionData?.flags?.user?.is_sympl,
    [sessionData]
  );

  const jobPosition = useMemo(
    () => sessionData?.flags?.user?.job_position ?? '',
    [sessionData]
  );

  // TODO: actively use
  const customer = useMemo(
    () => customerData?.customer ?? null,
    [customerData]
  );

  const subscription = useMemo(
    () => customer?.subscription ?? null,
    [customer]
  );

  const hasIntakes = useMemo(() => customer?.has_intakes ?? false, [customer]);

  const hasVacancies = useMemo(
    () => customer?.has_vacancies ?? false,
    [customer]
  );

  const hasPublishedVacancy = useMemo(
    () => customer?.has_published_vacancy ?? false,
    [customer]
  );

  const customerColors = useMemo(() => customer?.colors ?? [], [customer]);

  const hasIntegrationsEnabled = useMemo(
    () => customer?.integrations_enabled ?? false,
    [customer]
  );

  const hasReachedVacsLimit = useMemo(
    () =>
      subscription === null
        ? false
        : subscription.current_live_vacs === subscription.max_live_vacs,
    [subscription]
  );

  useEffect(() => {
    // (RE)FETCH DATA
    if (activeCustomer) {
      refetchCustomer();
      refetchBrands();
    }
  }, [activeCustomer]);

  useEffect(() => {
    // Update Intercom data - booster flag
    if (hasIntakes) updateData({ isBooster: true });
  }, [hasIntakes]);

  const setActiveCustomer = useCallback(
    async (customerId?: number) => {
      // Update the user session
      if (customerId !== activeCustomer) {
        setLocalAppSession({
          activeCustomer: customerId,
          activeVacancy: undefined,
        });

        // update the last used customer
        await updateLastUsedCustomer({
          variables: {
            input: {
              customer_id: customerId,
            },
          },
        });
      }
    },
    [activeCustomer]
  );

  const unsetActiveVacancy = () => {
    setLocalAppSession({
      activeVacancy: undefined,
    });
  };

  const setActiveVacancy = useCallback(
    (config: ActiveVacancySetterConfig) => {
      const { vacancyId, customerId } = config;
      // Update the user session
      if (vacancyId !== activeVacancy) {
        setLocalAppSession(
          customerId
            ? {
                activeVacancy: vacancyId,
                activeCustomer: customerId,
              }
            : {
                activeVacancy: vacancyId,
              }
        );
      }
    },
    [activeVacancy]
  );

  useEffect(() => {
    const checkStorage = () => {
      const { activeCustomer: newCustomer, activeVacancy: newVacancy } =
        getLocalAppSession();

      if (!newCustomer && !newVacancy) return;

      if (newCustomer !== activeCustomer) {
        if (location.pathname.startsWith(`${Routes.PROCEDURES}/`))
          navigate(Routes.CANDIDATES);

        _setActiveCustomer(newCustomer);
      }

      if (newVacancy !== activeVacancy) _setActiveVacancy(newVacancy);
    };

    window.addEventListener('storage', checkStorage);

    return () => {
      window.removeEventListener('storage', checkStorage);
    };
  }, [activeCustomer, activeVacancy, navigate, setActiveVacancy]);

  const resetContext = () => {
    unsetActiveVacancy();
  };

  const [prevLocation, setPrevLocation] = useState<string>('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  return (
    <NavigationContext.Provider
      value={{
        activeVacancy,
        currentVacancy,
        setActiveVacancy,
        unsetActiveVacancy,
        activeCustomer,
        setActiveCustomer,
        isAdmin,
        jobPosition,
        refetchCurrentVacancy,
        hasIntakes,
        hasIntegrationsEnabled,
        hasVacancies,
        hasPublishedVacancy,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        resetContext,
        prevLocation,
        setPrevLocation,
        clearResourceSelection,
        setClearResourceSelection,
        customerColors,
        customer,
        subscription,
        hasReachedVacsLimit,
        refetchCustomer,
        vacIsPublished: currentVacancy?.published ?? false,
        vacIsBooster: currentVacancy?.is_booster ?? false,
        vacSpendEnabled:
          currentVacancy?.spend_status !== VacancySpendStatus.NONE,
        hasSymplVacancyPage: !currentVacancy?.has_custom_url,
        brands,
        refetchBrands,
        vacancyLoading,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
