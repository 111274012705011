import React, { useRef, useState } from 'react';
import 'twin.macro';
import { Modal } from 'components/page/app-page/Modal';
import ResourceUpload, {
  ResourceUploadProps,
} from 'components/upload/ResourceUpload';
import Dropdown, { DropdownItem } from 'components/dropdown/Dropdown';
import { FileTypes } from 'types/fileTypes';
import Button from 'components/button/Button';

import useNavigationContext from 'hooks/context/nav-context';

import { getValidationRules } from 'utils/resourceHelpers';
import { DropzoneFileType } from 'components/upload/dropzone/Dropzone';
import ApiDropdown from 'components/dropdown/api-dropdown/ApiDropdown';

export interface ResourcesModalProps extends ResourceUploadProps {
  show: boolean;
  mode?: 'logo' | 'visual' | 'image' | 'video';
  onClose: () => void;
  brandId?: number;
}
export interface IResourcesFilterProps {
  vacancy?: number;
  brand?: number;
}

const getFileTypes = (
  mode: ResourcesModalProps['mode']
): DropzoneFileType[] => {
  switch (mode) {
    case 'logo':
    case 'image':
      return [FileTypes.PNG, FileTypes.JPG, FileTypes.JPEG];
    case 'video':
      return [FileTypes.MP4];
    default:
      return [FileTypes.PNG, FileTypes.JPG, FileTypes.JPEG, FileTypes.MP4];
  }
};

const ResourcesModal = ({
  show,
  mode,
  channel,
  onClose,
  brandId,
  ...props
}: ResourcesModalProps) => {
  const { hasVacancies, clearResourceSelection, brands } =
    useNavigationContext();

  const resourceUploadRef = useRef(null);

  const [resourcesFilter, setResourcesFilter] = useState<IResourcesFilterProps>(
    { brand: brandId, vacancy: undefined }
  );

  return (
    <Modal
      show={show}
      initialFocus={resourceUploadRef}
      onClose={onClose}
      children={
        <div tw="min-w-[80vw] max-w-[80vw]">
          <section tw="flex flex-col justify-between mb-3 sm:flex-row sm:place-items-center sm:mb-0">
            <h1 tw="text-3xl font-semibold text-gray-800 mb-4 mt-6">
              Media library
            </h1>
            <div tw="flex gap-4">
              {brands.length > 0 && (
                <Dropdown
                  label="Filter by brand"
                  onChange={(key) =>
                    setResourcesFilter((prevFilter) => ({
                      ...prevFilter,
                      brand: key as number,
                    }))
                  }
                  onClear={() =>
                    setResourcesFilter((prevFilter) => ({
                      ...prevFilter,
                      brand: undefined,
                    }))
                  }
                  defaultValue={resourcesFilter?.brand}
                  items={brands.map((v) => ({ key: v.id, label: v.name }))}
                  clearFilterButton={true}
                  value={resourcesFilter?.brand}
                />
              )}
              {hasVacancies && (
                <ApiDropdown
                  placeholder="Filter by campaign"
                  enableSearch
                  mode="key"
                  type="vacancies"
                  onChange={(data) => {
                    const key = (data as DropdownItem).key;
                    setResourcesFilter((prevFilter) => ({
                      ...prevFilter,
                      vacancy: key as number,
                    }));
                  }}
                  onClear={() =>
                    setResourcesFilter((prevFilter) => ({
                      ...prevFilter,
                      vacancy: undefined,
                    }))
                  }
                  clearFilterButton={true}
                  value={resourcesFilter?.vacancy}
                />
              )}
            </div>
          </section>
          {!!channel && (
            <section tw="mb-5">
              <ul tw="text-sm list-disc pl-4 text-gray-400">
                {getValidationRules(channel).map((rule) => (
                  <li key={rule}>{rule}</li>
                ))}
              </ul>
            </section>
          )}
          <section ref={resourceUploadRef}>
            <ResourceUpload
              fileTypes={getFileTypes(mode)}
              isLogo={mode === 'logo'}
              resourcesFilter={resourcesFilter}
              enableToggle={false}
              {...props}
            />
          </section>
          <section tw="flex justify-between">
            <Button variant="link" onClick={clearResourceSelection}>
              Clear Selection
            </Button>
            <div tw="flex space-x-1">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={onClose}>Save</Button>
            </div>
          </section>
        </div>
      }
      hideCloseButton
    />
  );
};

export default ResourcesModal;
