import tw, { css, styled, theme } from 'twin.macro';
import React from 'react';
import {
  ArrowBendUpRight,
  ChatCenteredDots,
  ThumbsUp,
  PaperPlaneTilt,
} from '@phosphor-icons/react';
import {
  AdEditorPreviewProps,
  PreviewInterfaceMode,
} from 'components/ad-builder/Preview';
import AdEditorVideoContainer from 'components/ad-builder/VideoContainer';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import Img from 'components/image/Image';
import AdEditorTextArea from 'components/ad-builder/ad-editor-textarea/TextArea';
import AdEditorStencilCanvas from 'components/ad-builder/StencilCanvas';
import {
  AdInteractionButton,
  AdUserInteraction,
  AdUserInteractionsWrapper,
} from 'components/ad-builder/LikeCommentShareBar';
import { placeholderMock as placeholder } from 'mocks/advertising';
import SymplLogo from 'assets/sympl-logo-cropped.png';
import EditableInput from 'components/form/editable-input/EditableInput';
import { AdCreativeType } from 'types/adTypes';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import { CTALabel } from 'data/adEditorCTA';

const LinkedInFeedPreview: React.FC<AdEditorPreviewProps> = () => {
  const { currentVariant, setActiveTab, updateCurrentVariant } =
    useAdEditorContext();

  return (
    <AdContainer>
      <div tw="flex flex-row text-xs text-gray-500 gap-x-3 h-12 m-2">
        <Img src={SymplLogo} alt="logo" />
        <div tw="flex flex-col">
          <span tw="text-black font-medium">sympl</span>
          <label>2,015 followers</label>
          <label>Promoted</label>
        </div>
      </div>

      <AdEditorTextArea />

      {currentVariant?.creative_type === AdCreativeType.VIDEO ? (
        <AdEditorVideoContainer />
      ) : (
        <AdEditorStencilCanvas />
      )}
      <AdCtaContainer>
        <AdCtaTextWrapper>
          <EditableInput
            id="cta-title"
            key={currentVariant?.headline ?? 'cta-title'}
            defaultValue={currentVariant?.headline}
            placeholder={placeholder.headline}
            onChange={(value) => updateCurrentVariant('headline', value)}
            editorRows={2}
          >
            {currentVariant?.headline}
          </EditableInput>
          <AdCtaUrl>sympl.be</AdCtaUrl>
        </AdCtaTextWrapper>
        <AdCtaButtonWrapper>
          <AdCtaButton
            onClick={() => setActiveTab?.(AdEditorSideBarTabs.BANNER_OPTIONS)}
          >
            {currentVariant?.cta || CTALabel.SIGN_UP}
          </AdCtaButton>
        </AdCtaButtonWrapper>
      </AdCtaContainer>
      <AdUserInteractionsWrapper>
        <AdUserInteraction>
          <AdInteractionButton>
            <ThumbsUp size={18} weight="bold" />
            <span tw="hidden md:(block ml-1)">Like</span>
          </AdInteractionButton>
        </AdUserInteraction>
        <AdUserInteraction>
          <AdInteractionButton>
            <ChatCenteredDots size={18} weight="bold" />
            <span tw="hidden md:(block ml-1)">Comment</span>
          </AdInteractionButton>
        </AdUserInteraction>
        <AdUserInteraction>
          <AdInteractionButton>
            {/* <BsArrowReturnLeft size={18} tw="rotate-180" /> */}
            <ArrowBendUpRight size={18} weight="bold" />
            <span tw="hidden md:(block ml-1)">Share</span>
          </AdInteractionButton>
        </AdUserInteraction>
        <AdUserInteraction>
          <AdInteractionButton>
            <PaperPlaneTilt size={18} weight="duotone" />
            <span tw="hidden md:(block ml-1)">Send</span>
          </AdInteractionButton>
        </AdUserInteraction>
      </AdUserInteractionsWrapper>
    </AdContainer>
  );
};

const AdContainer = styled.div<{ interfaceMode?: PreviewInterfaceMode }>`
  ${tw`bg-white h-auto rounded-lg overflow-hidden shadow-md  min-w-[350px] sm:min-w-[400px] max-w-[400px]`}
  ${css`
    #cta-title {
      font-size: 1em;
      font-weight: 600;
      color: ${theme`colors.gray.700`};
    }

    #description {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      overflow-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `}
`;

const AdCtaContainer = styled.div(
  tw`grid grid-cols-4 p-4 justify-between max-w-full bg-[#eef3f8] border-b border-[#dfe1e4]`
);

const AdCtaUrl = styled.label(
  tw`overflow-hidden text-ellipsis whitespace-nowrap break-words text-xs`
);

const AdCtaTextWrapper = styled.div(
  tw`grow flex flex-col shrink flex-nowrap text-gray-500 col-start-1 col-end-5 basis-0 max-w-[90%]`
);

const AdCtaButtonWrapper = styled.div(
  tw`flex justify-center col-start-5 col-end-6 `
);

const AdCtaButton = styled.button(
  tw` text-sm my-2 h-6 px-3 font-semibold  transition cursor-pointer border min-w-[100px]  rounded-2xl text-[#0a66c2]  border-[#0a66c2]  hover:(bg-blue-200 border-2) hover:(ring-2 ring-indigo-400 bg-indigo-50 )`
);

export default LinkedInFeedPreview;
