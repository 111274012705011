import React from 'react';
import { GenericSettings } from './components/GenericSettings';

export type AccountSettingsForm =
  | 'job-position'
  | 'signature-job-position'
  | 'language'
  | 'password'
  | 'new-password'
  | 'repeat-password';

interface Setting {
  title: string;
  fields: AccountSettingsForm[];
}

const settings: Setting[] = [
  {
    title: 'User settings',
    fields: ['job-position', 'signature-job-position'], //, 'language'
  },
  {
    title: 'Password settings',
    fields: ['password', 'new-password', 'repeat-password'],
  },
];

const AccountSettings = () => {
  return (
    <>
      {settings.map(({ title, fields }: Setting) => (
        <GenericSettings key={title} title={title} fields={fields} />
      ))}
      {/*<UserSettings />
      <PasswordSettings />*/}
    </>
  );
};

export default AccountSettings;
