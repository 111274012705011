import tw, { styled } from 'twin.macro';
import React, { useMemo } from 'react';
import FacebookFeedPreview from './channels/facebook/FeedPreview';
import InstagramFeedPreview from './channels/instagram/FeedPreview';
import LinkedInFeedPreview from './channels/linkedin/FeedPreview';
import InstagramStoryPreview from './channels/instagram/StoryPreview';
import FacebookReelsPreview from './channels/facebook/ReelsPreview';
import InstagramReelsPreview from './channels/instagram/ReelsPreview';
import TikTokPreview from './channels/tiktok/Preview';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import AdEditorToolbar from './Toolbar';
import { AdChannel, AdPlacement } from 'types/adTypes';
import VideoGenProvider from 'context/VideoGenContext';

export interface AdEditorPreviewProps {
  id?: string;
  functionTitle?: string;
}

export type PreviewInterfaceMode = 'light' | 'dark';

export const AdEditorPreview: React.FC<AdEditorPreviewProps> = ({
  id = 'preview',
  functionTitle,
}) => {
  const {
    currentVariant,
    previewChannel,
    selectedChannels,
    setPreviewChannel,
    setActiveTab,
  } = useAdEditorContext();

  const previewProps = useMemo(
    () => ({
      id,
      functionTitle,
    }),
    [id, functionTitle]
  );

  const previewer = useMemo(() => {
    switch (currentVariant?.placement) {
      // Feed
      case AdPlacement.FEED:
        switch (previewChannel) {
          case AdChannel.FACEBOOK:
            return <FacebookFeedPreview {...previewProps} />;
          case AdChannel.INSTAGRAM:
            return <InstagramFeedPreview {...previewProps} />;
          case AdChannel.LINKEDIN:
            return <LinkedInFeedPreview {...previewProps} />;
          default:
            setPreviewChannel(selectedChannels[0]);
            break;
        }
        break;
      // Stories
      case AdPlacement.STORIES:
        switch (previewChannel) {
          case AdChannel.FACEBOOK:
          case AdChannel.INSTAGRAM:
            return <InstagramStoryPreview {...previewProps} />;
          default:
            setPreviewChannel(selectedChannels[0]);
            break;
        }
        break;
      // Reels
      case AdPlacement.REELS:
        switch (previewChannel) {
          case AdChannel.FACEBOOK:
            return <FacebookReelsPreview {...previewProps} />;
          case AdChannel.INSTAGRAM:
            return <InstagramReelsPreview {...previewProps} />;
          case AdChannel.TIKTOK:
            return <TikTokPreview {...previewProps} />;
          default:
            setPreviewChannel(selectedChannels[0]);
            break;
        }
        break;
    }
  }, [
    currentVariant?.placement,
    previewChannel,
    previewProps,
    selectedChannels,
    setPreviewChannel,
  ]);

  return (
    <div tw="relative">
      <VideoGenProvider>
        {previewer}
        <ToolbarWrapper id="toolbar">
          <AdEditorToolbar setActiveTab={setActiveTab} />
        </ToolbarWrapper>
      </VideoGenProvider>
    </div>
  );
};

const ToolbarWrapper = styled.div(
  tw`mb-2 max-w-fit sm:(mt-0 absolute top-0 -right-16 left-auto) z-50 fixed bg-white bottom-0 left-0`
);

export default AdEditorPreview;
