import React from 'react';
import tw, { css, styled, theme } from 'twin.macro';
import SymplLogo from 'assets/sympl-logo-cropped.png';
import {
  ShareFat,
  ChatText,
  ChatCircleDots,
  House,
  HeartStraight,
  Users,
  User,
  Plus,
} from '@phosphor-icons/react';

import useAdEditorContext from 'hooks/context/ad-editor-context';
import { AdEditorPreviewProps } from 'components/ad-builder/Preview';
import AdEditorVideoContainer from 'components/ad-builder/VideoContainer';
import EditableInput from 'components/form/editable-input/EditableInput';
import Img from 'components/image/Image';
import { CTALabel } from 'data/adEditorCTA';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import useNavigationContext from 'hooks/context/nav-context';

const Preview: React.FC<AdEditorPreviewProps> = () => {
  const { currentVariant, uploadMode, setActiveTab, updateCurrentVariant } =
    useAdEditorContext();

  const { currentVacancy } = useNavigationContext();

  return (
    <TikTokContainer>
      <TopSection>
        <span>Following</span>
        <div>
          <span>For You</span>
          <hr tw="border-b mx-4 mt-0.5" />
        </div>
      </TopSection>
      <MidSection>
        <div tw="flex flex-col w-[85%]">
          <span
            tw="font-medium"
            css={[
              currentVariant?.path && !uploadMode ? 'white' : tw`text-gray-500`,
            ]}
          >
            @{currentVacancy?.company ?? 'sympl'}
          </span>
          <div tw="mt-1 mb-2">
            <EditableInput
              id="text"
              key={currentVariant?.text ?? 'text'}
              defaultValue={currentVariant?.text}
              placeholder={'Looking for a cool job? Xyz got you covered!'}
              onChange={(value) => updateCurrentVariant('text', value)}
              editorRows={2}
              style={{
                color:
                  currentVariant?.path && !uploadMode
                    ? 'white'
                    : theme`colors.gray.500`,
                fontSize: '0.875rem',
                wordWrap: 'break-word',
                backgroundColor: 'transparent',
              }}
              styleWrapper={[
                tw`
                    hover:(ring-2 ring-indigo-400 bg-indigo-50/25 rounded-sm)
                    focus:(ring-2 ring-indigo-400 bg-indigo-50/25 rounded-sm)
                  `,
              ]}
            >
              {currentVariant?.text}
            </EditableInput>
          </div>
          <Button
            onClick={() => setActiveTab?.(AdEditorSideBarTabs.BANNER_OPTIONS)}
          >
            {currentVariant?.cta || CTALabel.SIGN_UP}
          </Button>
        </div>
        <div tw="w-fit">
          <ActionsBar>
            <Action>
              <LogoWrapper>
                <Img
                  src={
                    currentVacancy?.brand?.default_logo?.thumbnail_path ??
                    SymplLogo
                  }
                  alt="logo"
                  tw="h-full w-full object-contain"
                />
              </LogoWrapper>
            </Action>
            <Action>
              <HeartStraight weight="fill" size={25} />
              1572
            </Action>
            <Action>
              <ChatCircleDots weight="fill" size={25} />
              256
            </Action>
            <Action>
              <ShareFat weight="fill" size={25} />
              89
            </Action>
          </ActionsBar>
        </div>
      </MidSection>
      <VideoWrapper>
        <AdEditorVideoContainer />
      </VideoWrapper>
      <BottomSection>
        <BottomItem>
          <House weight="fill" size={20} />
          Home
        </BottomItem>
        <BottomItem>
          <Users weight="bold" size={20} />
          Friends
        </BottomItem>
        <BottomItem>
          <CreateButton>
            <Plus weight="bold" size={15} color="#161824" />
          </CreateButton>
        </BottomItem>
        <BottomItem>
          <ChatText weight="bold" size={20} />
          Inbox
        </BottomItem>
        <BottomItem>
          <User weight="bold" size={20} />
          Profile
        </BottomItem>
      </BottomSection>
    </TikTokContainer>
  );
};

const TikTokContainer = styled.div`
  ${tw`
  text-white relative rounded-xl overflow-hidden shadow-md w-[23rem] sm:w-96 max-[400px]:(w-[95vw])
`}
  ${css`
    aspect-ratio: 9/16;

    #description {
      color: white !important;
      pointer-events: auto;
    }

    #description:focus {
      color: black !important;
    }
  `}
`;

const VideoWrapper = styled.div(tw`z-10 h-full overflow-hidden bg-gray-200`);
const TopSection = styled.div(
  tw`absolute top-0 z-30 text-white text-center w-full p-3 flex justify-center space-x-4 font-semibold text-sm mt-2`
);

const MidSection = styled.div(
  tw`flex flex-row items-end justify-between p-3 absolute w-full -bottom-10 z-30 pb-16`
);

const Button = styled.div(
  tw`py-2 text-white text-center font-light text-sm rounded-sm mr-5 bg-[#e62d51] cursor-pointer hover:(border-2 border-indigo-400 )`
);

const ActionsBar = styled.div(
  tw`flex flex-col gap-y-3 items-center text-center justify-center`
);

const Action = styled.div(
  tw`text-white text-sm flex flex-col items-center justify-center`
);

const LogoWrapper = styled.div(
  tw`overflow-hidden rounded-full h-10 w-10 border border-gray-300 flex flex-col items-center`
);

const BottomSection = styled.div(
  tw`flex flex-row px-2 py-1 gap-x-6 items-center justify-center bg-[#161824]`
);

const BottomItem = styled.div(tw`flex flex-col items-center text-xs`);

const CreateButton = styled.div(
  tw`
    flex flex-col items-center justify-center bg-white w-10 h-6 rounded-[10px]
    border-x-[3px] border-l-[#6ac9d0] border-r-[#e81f54]
  `
);

export default Preview;
