import tw, { styled } from 'twin.macro';
import React, { FC } from 'react';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import { AdCreativeType } from 'types/adTypes';

interface TabsProps {
  activeTabIndex: number;
  items: React.ReactNode[] | AdEditorSideBarTabs[];
  onChange: (currentTabIndex: number) => void;
  isTabsDisabled?: boolean;
}

// Check if items are of type AdEditorSideBarTabs or not
const isAdEditorSideBarTabs = (item: React.ReactNode | AdEditorSideBarTabs) =>
  Object.values(AdEditorSideBarTabs).includes(
    item!.toString() as AdEditorSideBarTabs
  );

// Get the index of the item (of type AdEditorSideBarTabs)
const adEditorSideBarTabsIndex = (
  item: React.ReactNode | AdEditorSideBarTabs
) =>
  Object.values(AdEditorSideBarTabs).indexOf(
    item!.toString() as AdEditorSideBarTabs
  );

// Get the index based on type of the item
const getIndex = (item: any, index: number) =>
  isAdEditorSideBarTabs(item) ? adEditorSideBarTabsIndex(item) : index;

const Tabs: FC<TabsProps> = (props) => {
  const { activeTabIndex, items, onChange, isTabsDisabled } = props;
  const { currentVariant } = useAdEditorContext();

  const tabClickedHandler = (tabIndex: number) => onChange(tabIndex);

  return (
    <div tw="w-full overflow-x-auto ">
      <TabList isDisabled={isTabsDisabled}>
        {items &&
          [...items].map((item, index) => (
            <TabItem
              key={index}
              isActive={activeTabIndex === getIndex(item, index)}
              isDisabled={
                item !== AdEditorSideBarTabs.VISUAL_OPTIONS &&
                currentVariant?.creative_type === AdCreativeType.VIDEO
              }
              onClick={() => tabClickedHandler(getIndex(item, index))}
            >
              {item}
            </TabItem>
          ))}
      </TabList>
    </div>
  );
};

const TabList = styled.ul<{ isDisabled?: boolean }>`
  ${tw`gap-4 flex`}
  ${({ isDisabled }) => isDisabled && tw`opacity-10 pointer-events-none`}
`;

const TabItem = styled.li<{
  isActive: boolean;
  isDisabled: boolean;
}>`
  ${tw`text-center mx-2 font-medium cursor-pointer transition-colors px-1 py-1 min-w-fit hover:(border-b-2 border-gray-200)`}
  ${({ isActive }) => isActive && tw`border-b-2 border-gray-300`}
  ${({ isDisabled }) => isDisabled && tw`text-gray-300 pointer-events-none`}
`;

export default Tabs;
