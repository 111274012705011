import 'twin.macro';

import ToolTip from 'components/tooltip/ToolTip';
import React from 'react';

const MissingFields = ({ missingFields }: { missingFields: string[] }) =>
  missingFields.length ? (
    <ToolTip
      content={
        <ul tw="text-white text-sm p-2 pl-4 ml-2 list-disc">
          {missingFields.map((field) => (
            <li key={field}>{field}</li>
          ))}
        </ul>
      }
      placement="bottom"
      arrow
    >
      <p tw="text-sm font-medium cursor-default flex items-center transition hover:text-slate-500 focus:outline-none">
        {'Missing information'} ({missingFields.length})
      </p>
    </ToolTip>
  ) : (
    <></>
  );

export default MissingFields;
