import { getLocalAppSession } from './storageHelpers';
import { capitalize, textToHtml } from './baseHelpers';
import { generatePromptKeywords } from './vacancy-generator/prompts';
import { getCurrentAuthToken } from './authHelpers';

type ChatGPTAgent = 'user' | 'system' | 'assistant';

export interface ChatGPTMessage {
  role: ChatGPTAgent;
  content: string;
}

interface OpenAIThreadsPayload {
  identifier: string;
  messages: ChatGPTMessage[];
  thread_id?: string;
  vacancy_id: number;
  stream?: boolean;
}

type OpenAIThreadsResponse = { thread_id: string; response: string };

export const getOpenAiThreadsResponse = ({
  onChange,
  signal,
  ...body
}: OpenAIThreadsPayload & {
  onChange?: (resp: string) => void;
  signal?: AbortSignal;
}) =>
  new Promise<OpenAIThreadsResponse>(async (resolve, reject) => {
    const response = await fetch(
      `${import.meta.env.VITE_BASE_URL_API}/v4/ai/generate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Active-Customer': `${getLocalAppSession()?.activeCustomer}`,
          Authorization: `Bearer ${getCurrentAuthToken()}`,
        },
        body: JSON.stringify(body),
        signal,
      }
    );

    if (!response.ok || !response.body) {
      reject(response.statusText);
      return;
    }

    if (!body.stream || !onChange) {
      const result = await response.json();
      resolve(result?.data);
      return;
    }

    const output = await handleThreadsStream(response.body, (value) =>
      onChange(value)
    );
    resolve(output);
  });

export const getFormattedText = async (
  text: string,
  vacancyId: number,
  identifier: string,
  threadId: string
) => {
  try {
    const { response } = await getOpenAiThreadsResponse({
      identifier,
      vacancy_id: vacancyId,
      messages: [generatePromptKeywords()],
      thread_id: threadId,
      stream: false,
    });

    // Remove possible new line(s) from start of output
    if (text?.startsWith('\n')) text = text.slice(1);
    if (text?.startsWith('\n')) text = text.slice(1);
    if (text?.trim().length === 1) text = text.slice(1);
    if (text?.startsWith('\n')) text = text.slice(1);

    // Convert to html
    let html = textToHtml(text ?? '');

    // Get keywords from keywords output
    const keywords = response
      ?.split('\n')
      .map((word) => word.trim())
      .filter((word) => word) ?? [''];

    // Remove the numbering
    let formattedKeywords = keywords.flatMap(
      (word) => word.match(/[a-zA-Z]+/g) ?? ['']
    );
    const blacklistedKeywords = ['en'];

    formattedKeywords = formattedKeywords.filter(
      (word) => !blacklistedKeywords.includes(word)
    );

    // Extend array by adding duplicates of word in different casings
    formattedKeywords = [
      ...formattedKeywords,
      ...formattedKeywords.map((word) => capitalize(word)),
      ...formattedKeywords.map((word) => word.toLowerCase()),
    ];

    // If keywords were found, wrap them in strong tags
    formattedKeywords.forEach((word) => {
      html = html?.replaceAll(` ${word} `, ` <strong>${word}</strong> `);
      html = html?.replaceAll(` ${word},`, ` <strong>${word}</strong>,`);
      html = html?.replaceAll(` ${word}.`, ` <strong>${word}</strong>.`);
    });

    return html;
  } catch (error: any) {
    console.error(error);
    return text;
  }
};

export const handleThreadsStream = async (
  stream: ReadableStream<Uint8Array>,
  onProgress: (value: string) => void
): Promise<OpenAIThreadsResponse> => {
  const reader = stream.getReader();
  const decoder = new TextDecoder('utf-8');
  let done = false;
  let chunkValue = '';

  let threadId = '';
  const threadPrefix = 'thread_id:';

  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;

    const decodedValue = decoder.decode(value);

    // Extract thread id from the message
    if (decodedValue.startsWith(threadPrefix)) {
      threadId = decodedValue.substring(threadPrefix.length);
      continue;
    }

    // TODO: check for infinite loop
    if (!decodedValue) continue;

    chunkValue += decodedValue;
    onProgress(chunkValue);
  }
  return { thread_id: threadId, response: chunkValue };
};
