import React from 'react';
import tw, { css, styled, theme } from 'twin.macro';
import {
  BookmarkSimple,
  ChatCircleDots,
  CaretRight,
  HeartStraight,
  PaperPlaneTilt,
} from '@phosphor-icons/react';
import SymplLogo from 'assets/sympl-logo-cropped.png';
import { AdEditorPreviewProps } from 'components/ad-builder/Preview';
import { AdEditorStencilCanvas as AdEditorStencil } from 'components/ad-builder/StencilCanvas';
import AdEditorVideoContainer from 'components/ad-builder/VideoContainer';
import Img from 'components/image/Image';

import useAdEditorContext from 'hooks/context/ad-editor-context';
import AdEditorTextArea from 'components/ad-builder/ad-editor-textarea/TextArea';
import { AdUserInteraction } from 'components/ad-builder/LikeCommentShareBar';
import { AdCreativeType } from 'types/adTypes';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import { CTALabel } from 'data/adEditorCTA';
import useNavigationContext from 'hooks/context/nav-context';

export const FeedPreview: React.FC<AdEditorPreviewProps> = ({ id }) => {
  const { currentVariant, setActiveTab } = useAdEditorContext();
  const { currentVacancy } = useNavigationContext();

  return (
    <>
      <AdContainer>
        <AdHeader id={id}>
          <AdSymplLogoWrapper>
            <Img
              src={currentVacancy?.brand?.facebookPage?.logo_url ?? SymplLogo}
              alt="logo"
            />
          </AdSymplLogoWrapper>
          <div tw="items-start justify-start w-auto grow mr-2 h-10">
            <AdCompanyName>
              {currentVacancy?.brand?.facebookPage?.name ?? 'sympl'}
            </AdCompanyName>
            <div tw="flex items-center">
              <AdSponsoredText>Sponsored</AdSponsoredText>
            </div>
          </div>
          <div tw="flex w-5 justify-evenly items-center">
            {[...Array(3)].map((_value, index) => (
              <span key={index} tw="bg-gray-500 rounded-full h-1 w-1 block" />
            ))}
          </div>
        </AdHeader>

        {currentVariant?.creative_type === AdCreativeType.VIDEO ? (
          <AdEditorVideoContainer />
        ) : (
          <AdEditorStencil />
        )}

        <AdCTA
          onClick={() => setActiveTab?.(AdEditorSideBarTabs.BANNER_OPTIONS)}
        >
          <p tw="text-sm">{currentVariant?.cta || CTALabel.SIGN_UP}</p>
          <CaretRight size={20} />
        </AdCTA>

        <div tw="flex justify-between mx-1.5 px-2 pt-3">
          <div tw="flex space-x-3 items-center place-content-center">
            <AdUserInteraction>
              <HeartStraight size={24} />
            </AdUserInteraction>
            <AdUserInteraction>
              <ChatCircleDots size={24} />
            </AdUserInteraction>
            <AdUserInteraction>
              <PaperPlaneTilt size={24} />
            </AdUserInteraction>
          </div>
          <div>
            <AdUserInteraction>
              <BookmarkSimple size={24} />
            </AdUserInteraction>
          </div>
        </div>

        <div tw="my-2">
          <AdEditorTextArea />
        </div>
      </AdContainer>
    </>
  );
};

const AdContainer = styled.div`
  ${tw`bg-white w-auto h-auto min-w-[23rem] max-[400px]:(w-[95vw]) rounded-lg overflow-hidden shadow-md sm:max-w-[25rem]`}
  ${css`
    #cta-title {
      font-size: 1em;
      font-weight: 600;
      color: ${theme`colors.gray.700`};
    }

    #description {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      overflow-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    #description:focus {
      white-space: normal;
    }
  `}
`;

const AdCTA = styled.div`
  ${tw`flex justify-between items-center w-full px-4 py-2 font-medium  text-white bg-red-400 cursor-pointer hover:(border-2 border-indigo-400 )`}
`;

const AdHeader = styled.header`
  ${tw`flex flex-row p-4 pb-0 items-center justify-between leading-snug mb-3`}
`;

const AdSymplLogoWrapper = styled.div`
  ${tw`overflow-hidden rounded-full h-10 w-10 border border-gray-300 mr-2`}
`;

const AdCompanyName = styled.p`
  ${tw`text-sm font-semibold`}
`;

const AdSponsoredText = styled.p`
  ${tw`text-gray-500 mr-0.5 text-xs`}
`;

export default FeedPreview;
