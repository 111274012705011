import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Sparkle, UploadSimple } from '@phosphor-icons/react';
import tw, { css, styled } from 'twin.macro';
import { AdPlacement } from 'types/adTypes';
import { getCanvasHeight, getCanvasWidth } from 'utils/adEditorCanvasHelpers';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import { fabric } from 'fabric';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import useDebouncedCallback from 'hooks/debounceCallback';
import { deleteTspan, getOverlay, OVERLAY_OPTIONS } from 'utils/overlayHelpers';
import { initAligningGuidelines } from 'utils/fabricjs/aligning_guidelines';
import { Stack } from 'immutable';
import useNavigationContext from 'hooks/context/nav-context';
import Divider from 'components/divider/Divider';
import useVideoGenContext from 'hooks/context/video-gen-context';

const MAX_LOGO_HEIGHT = 120;
const MAX_LOGO_WIDTH = 150;

const DEFAULT_FABRIC_PROPS = {
  hoverCursor: 'pointer',
  objectCaching: false,
  transparentCorners: false,
  cornerSize: 25,
  borderScaleFactor: 6,
  selectable: true,
  editable: true,
};

export const AdEditorStencilCanvas: React.FC = () => {
  const {
    currentVariant,
    setVariants,
    updateCurrentVariant,
    setActiveTab,
    activeTab,
    setSvgStack,
    svgStack,
    newSvgElement,
    unsetNewSvgElement,
    setUploadMode,
  } = useAdEditorContext();
  const { currentVacancy } = useNavigationContext();

  const svgCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [hasMounted, setHasMounted] = useState(false);
  // const [updateImage, setUpdateImage] = useState(false); //toggle -> changing the img source
  const [currentObject, setCurrentObject] = useState<fabric.Object | undefined>(
    undefined
  );
  const [isInCanvas, setIsInCanvas] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const canvasWidth = useMemo(
    () =>
      currentVariant?.placement ? getCanvasWidth(currentVariant.placement) : 1,
    [currentVariant?.placement]
  );

  const canvasHeight = useMemo(
    () =>
      currentVariant?.placement ? getCanvasHeight(currentVariant.placement) : 1,
    [currentVariant?.placement]
  );

  const crop = useMemo(
    () => currentVariant?.crop,
    [
      currentVariant?.crop?.height,
      currentVariant?.crop?.width,
      currentVariant?.crop?.x,
      currentVariant?.crop?.y,
    ]
  );

  /**
   * Returns an object of the canvas
   *
   * @param str -  input object
   * @returns fabric.Object
   */
  const getCanvasObject = (str: 'bg' | 'logo') => {
    return canvas?.getObjects('image')[str === 'bg' ? 0 : 1];
  };

  const changeCanvasImage = useMemo(() => {
    if (!hasMounted) return null;
    return !currentVariant?.path ? null : currentVariant?.path.path;
  }, [currentVariant?.path, hasMounted]);

  const onKeyPress = useCallback(
    (evt: KeyboardEvent) => {
      if (!currentObject) return;
      if (
        currentObject.type === 'text' &&
        (currentObject as fabric.Textbox).isEditing
      ) {
        updateCurrentVariant('currentObject', {
          value: (currentObject as fabric.Textbox).text,
          fontFamily: (currentObject as fabric.Textbox).fontFamily,
          fontSize: (currentObject as fabric.Textbox).fontSize,
          textAlign: (currentObject as fabric.Textbox).textAlign,
          color: (currentObject as fabric.Textbox).fill,
          backgroundColor:
            (currentObject as fabric.Textbox).backgroundColor ?? '',
        });
      } else if (isInCanvas && evt.key === 'Backspace' && currentObject) {
        updateCurrentVariant('currentObject', {
          ...currentVariant?.currentObject,
          isDeleted: true,
        });
      }
    },
    [isInCanvas, currentObject]
  );

  /**
   * Read an SVG Image
   * @param svg => the image in svg format
   * @param isOverlay =>  It's an overlay
   *
   */

  const readSVGImage = async (svg: string, isOverlay = false) => {
    if (!canvas || !currentVariant) return;

    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
    //Remove the rectangle (the rectangle is normally the background color of the text)
    //Set the background color of the object fabric.Text
    //&& get preview image (Placement menu)
    let previewFound = false;
    svgDoc.querySelectorAll('g').forEach((group) => {
      const rect = group.querySelectorAll('rect');
      const text = group.querySelector('text');

      const img = group.querySelector('image');
      if (img && !previewFound && !isOverlay) {
        updateCurrentVariant(
          'previewImageElement',
          img.parentElement!.outerHTML
        );
        previewFound = true;
      }
      if (rect[0] && text) {
        const bgColor = rect[rect.length - 1].getAttribute('fill');
        if (!bgColor) return;
        text.setAttribute('style', `data-bg-color: ${bgColor}`);
        rect.forEach((v) => group.removeChild(v));
      }
    });

    //Old variants does still have tspan in their SVG -> remove them!
    if (svgDoc.documentElement.outerHTML.includes('tspan'))
      svgDoc.querySelectorAll('g > text').forEach((txt) => {
        const originalSvg = txt.parentElement!.outerHTML;
        const transformedSvg = deleteTspan(originalSvg);
        txt.parentElement!.outerHTML = transformedSvg;
      });

    const ctx = canvas.getContext();
    if (ctx) {
      canvas.clearContext(ctx);
      canvas.clear();
    }

    await (async () => {
      try {
        const objects = await new Promise<fabric.Object[]>(
          (resolve, reject) => {
            fabric.loadSVGFromString(
              svgDoc.documentElement.outerHTML,
              (objects) => {
                if (objects) resolve(objects);
                else reject(new Error('Reading SVG failed'));
              }
            );
          }
        );

        objects.forEach((v, i) => {
          v.set({ ...DEFAULT_FABRIC_PROPS });

          // Make sure the background image is not selectable
          if (i === 0 && v.type === 'image') v.set({ selectable: false });
          if (i === 1 && v.type === 'rect') v.set({ selectable: false });

          if (v.type === 'text') {
            const textB = v as fabric.Text;
            textB.set({
              text: textB.text!.replaceAll('{NEW_LINE_BREAK}', '\n'),
              textLines: textB.text!.includes('{NEW_LINE_BREAK}')
                ? textB.textLines
                : [textB.text!],
            });

            const width1 = textB.getBoundingRect().width + 1;
            const width2 = textB.textLines.reduce(
              (max, _, i) => Math.max(max, textB.getLineWidth(i)),
              0
            );
            textB.set({
              scaleX: 1,
              scaleY: 1,
              width:
                textB.textLines.length > 1 ? width1 : Math.max(width1, width2),
            });

            v = new fabric.Textbox((v as fabric.Text).text ?? '', {
              ...v,
              editable: true,
              centeredScaling:
                currentVariant.currentObject?.textAlign === 'center',
              type: 'text',
            });
          }

          canvas.add(v);
        });

        if (svgStack.size === 0 || isOverlay)
          setSvgStack((prev) =>
            prev.clear().push(svgDoc.documentElement.outerHTML)
          );

        canvas.requestRenderAll();
      } catch (error) {
        console.error('Error:', error);
      }
    })();
  };

  const debounceClick = useDebouncedCallback((evt) => {
    if (currentObject === evt.target! || !evt.target) return;

    if (evt.target.type === 'image') {
      setActiveTab(AdEditorSideBarTabs.VISUAL_OPTIONS);
      if (currentObject) setCurrentObject(undefined);
    } else if (evt.target!.type !== 'image') {
      setCurrentObject(evt.target);
    }
  }, 250);

  const debounceObjectChange = useDebouncedCallback(() => {
    const svg = canvas!.toSVG();
    if (svgStack.first() !== svg && svg.includes('<image'))
      setSvgStack((prevStack) => prevStack.push(svg));
  }, 250);

  const debounceObjectResizing = useDebouncedCallback((evt) => {
    if (!currentObject || currentObject.type !== 'text') return;
    const textBox = evt.target as fabric.Textbox;
    const newFontSize = textBox.fontSize! * textBox.scaleX!;
    updateCurrentVariant('currentObject', {
      ...currentVariant?.currentObject,
      fontSize: newFontSize.toFixed(0),
    });
    canvas?.requestRenderAll();
  }, 100);

  // when clicking outside the canvas
  const outsideCanvasClick = () => {
    if (!isInCanvas && currentObject) {
      canvas?.discardActiveObject();
      canvas?.requestRenderAll();
    }
  };

  const cropImage = () => {
    if (!canvas || !hasMounted || !currentVariant?.path) return;
    const background = getCanvasObject('bg') as fabric.Image;
    if (background) {
      const bgWidth = background.width ?? 0;
      const bgHeight = background.height ?? 0;
      const imgRatio = bgHeight / bgWidth;
      const canvasRatio = canvasHeight / canvasWidth;
      let cropX, cropY, cropWidth, cropHeight;
      if (
        !currentVariant.svg ||
        (currentVariant.svg && crop) ||
        (currentVariant.svg && crop === null)
      ) {
        // Compares the image ratio to the canvas ratio to check if the image is too wide and sets the width accordingly
        cropWidth = imgRatio < canvasRatio ? bgHeight / canvasRatio : bgWidth;
        // Compares the image ratio to the canvas ratio to check if the image is too tall and sets the height accordingly
        cropHeight = imgRatio > canvasRatio ? bgWidth * canvasRatio : bgHeight;
        // Gets the left position starting from the center of the image
        cropX = bgWidth / 2 - cropWidth / 2;
        // Gets the top position starting from the center of the image
        cropY = bgHeight / 2 - cropHeight / 2;
        updateCurrentVariant('crop', {
          unit: '%',
          x: (cropX / bgWidth) * 100,
          y: (cropY / bgHeight) * 100,
          width: (cropWidth / bgWidth) * 100,
          height: (cropHeight / bgHeight) * 100,
        });
      } else {
        cropWidth = Math.round(
          (canvasWidth / (background.scaleX! * background.width!)) * 100
        );
        cropHeight = Math.round(
          100 * (canvasHeight / (background.scaleY! * background.height!))
        );
        cropX = Math.round(
          (-background.left! / (background.width! * background.scaleX!)) * 100
        );
        cropY = Math.round(
          -100 * (background.top! / (background.height! * background.scaleY!))
        );
        if (cropWidth <= 100 && cropHeight <= 100) {
          updateCurrentVariant('crop', {
            unit: '%',
            x: cropX,
            y: cropY,
            width: cropWidth,
            height: cropHeight,
          });
          return;
        }
      }

      // set the background image as the first object in the canvas (at the bottom)
      canvas.sendToBack(background);
    }
  };

  const addText = (canvas: fabric.Canvas) => {
    const text = new fabric.Textbox('New Text', {
      ...DEFAULT_FABRIC_PROPS,
      fontFamily: 'Arial',
      fontSize: 56,
      fontWeight: 700,
      textAlign: 'left',
      fill: '#000000',
      backgroundColor: 'transparent',
      type: 'text',
      centeredScaling: false,
    });

    addAndRender(text, canvas);
  };

  const addRectangle = (canvas: fabric.Canvas) => {
    const rect = new fabric.Rect({
      ...DEFAULT_FABRIC_PROPS,
      width: 400,
      height: 150,
      fill: '#ffffff',
      type: 'rect',
    });

    addAndRender(rect, canvas);
  };

  // Fabric SVG export includes a <rect> element in the <g> element containing the <text> element, this is unwanted
  function removeRectFromTextGroup(svgElement: string) {
    const fullSVG = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1">${svgElement}</svg>`;
    const svgDoc = new DOMParser().parseFromString(fullSVG, 'image/svg+xml');
    const groups = svgDoc.querySelectorAll('g');

    groups.forEach((group) => {
      const rect = group.querySelector('rect');
      const text = group.querySelector('text');

      // If the <g> contains both a <rect> and a <text>, remove the <rect>
      if (rect && text) group.removeChild(rect);
    });

    const s = new XMLSerializer().serializeToString(svgDoc.documentElement);
    return s.replace(/^<svg[^>]*>|<\/svg>$/g, '');
  }

  const triggerSVGUpdate = () => updateCurrentVariant('svg', canvas?.toSVG());

  const addAndRender = (element: fabric.Object, canvas: fabric.Canvas) => {
    let newElement = element;
    if (element.type === 'text') {
      const svgElement = element.toSVG();
      const svgElementWithoutRect = removeRectFromTextGroup(svgElement);

      // convert the new svg string back to a fabric object and add it to the canvas
      fabric.loadSVGFromString(svgElementWithoutRect, (objects) => {
        newElement = objects[0];
        newElement.set({ ...DEFAULT_FABRIC_PROPS });
      });
    }

    canvas.add(newElement);
    canvas.setActiveObject(newElement);
    canvas.requestRenderAll();
    triggerSVGUpdate();
  };

  useEffect(() => {
    switch (newSvgElement) {
      case 'text':
        addText(canvas!);
        break;
      case 'rectangle':
        addRectangle(canvas!);
        break;
      default:
        break;
    }

    unsetNewSvgElement();
  }, [newSvgElement]);

  useEffect(() => {
    canvas?.on('object:modified', (event) => {
      if (!event.target) return;
      const svg = canvas.toSVG();
      if (svgStack.first() !== svg && svg.includes('<image')) {
        setSvgStack((prevStack) => prevStack.push(svg));
        updateCurrentVariant('svg', svg);
      }
    });
    canvas?.on('mouse:up', (evt) => {
      if (isDragging) {
        setIsDragging(false);
        if (!currentObject) {
          canvas?.discardActiveObject();
          return;
        }
      }
      debounceClick(evt);
    });
    canvas?.on('mouse:out', () => {
      setIsInCanvas(false);
    });
    canvas?.on('mouse:over', () => {
      setIsInCanvas(true);
    });
    canvas?.on('object:scaling', (evt) => {
      debounceObjectResizing(evt);
    });
    window.addEventListener('click', outsideCanvasClick);
    return () => {
      window.removeEventListener('click', outsideCanvasClick);
      canvas?.off('mouse:out');
      canvas?.off('mouse:up');
      canvas?.off('mouse:over');
      canvas?.off('object:modified');
      canvas?.off('object:scaling');
      canvas?.off('object:resizing');
    };
  }, [
    canvas,
    currentObject,
    isInCanvas,
    isDragging,
    currentVariant?.currentObject,
  ]);

  useEffect(() => {
    canvas?.on('object:moving', () => {
      setIsDragging(true);
      canvas.requestRenderAll();
    });
    return () => {
      canvas?.off('object:moving');
    };
  }, [canvas]);

  useEffect(() => {
    if (
      !hasMounted ||
      !currentVariant?.currentObject ||
      !canvas ||
      svgStack.size < 1
    )
      return;
    debounceObjectChange();
  }, [currentVariant?.currentObject]);

  useEffect(() => {
    if (!hasMounted || !canvas) return;
    if (svgStack.size === 1) return;
    const stack = svgStack.pop();
    (async () => await readSVGImage(stack.first(), false))();
    setSvgStack(stack);
  }, [currentVariant?.undoChange]);
  // on key press -> delete object when pressing Backspace

  useEffect(() => {
    window.addEventListener('keyup', onKeyPress, false);

    return () => {
      window.removeEventListener('keyup', onKeyPress, false);
    };
  }, [isInCanvas, currentObject]);

  //Remove the selected item
  useEffect(() => {
    if (
      hasMounted &&
      currentVariant?.currentObject?.isDeleted &&
      currentObject
    ) {
      canvas?.remove(currentObject);
      setActiveTab(undefined);

      triggerSVGUpdate();
    }
  }, [currentVariant?.currentObject?.isDeleted]);

  // Set currentObject values => this is needed for the SideBar
  useEffect(() => {
    if (!hasMounted) return;
    if (!currentObject) {
      if (activeTab) setActiveTab(AdEditorSideBarTabs.VISUAL_OPTIONS);
      return;
    }

    switch (currentObject.type) {
      case 'text':
        const currentText = currentObject as fabric.Text;
        updateCurrentVariant('currentObject', {
          value: currentText.text?.replaceAll('\n', ' '),
          fontFamily: currentText.fontFamily,
          fontSize: currentText.fontSize,
          textAlign: currentText.textAlign,
          color: currentText.fill,
          backgroundColor: currentText.backgroundColor ?? '',
          scaleX: 1,
          scaleY: 1,
        });
        setActiveTab(AdEditorSideBarTabs.TEXT_OPTIONS);
        break;
      default:
        updateCurrentVariant('currentObject', {
          backgroundColor: currentObject.fill ?? '',
        });
        setActiveTab(AdEditorSideBarTabs.SHAPE_OPTIONS);
        break;
    }
    canvas?.requestRenderAll();
  }, [currentObject, hasMounted]);

  //Update the currentObject (state) values => when changing in SideBar
  useEffect(() => {
    if (!currentVariant || !hasMounted || !currentObject) return;
    if (
      currentVariant.currentObject?.isDeleted ||
      !currentVariant.currentObject
    )
      return;
    switch (currentObject.type) {
      case 'text':
        const currentText = currentObject as fabric.Text;
        currentText.text = currentVariant.currentObject?.value;
        currentText.backgroundColor =
          currentVariant.currentObject?.backgroundColor;
        currentText.fill = currentVariant.currentObject?.color;
        currentText.fontSize = currentVariant.currentObject?.fontSize ?? 0;
        currentText.fontFamily = currentVariant.currentObject?.fontFamily;
        currentText.textAlign = currentVariant.currentObject?.textAlign;

        const width1 = currentText.getBoundingRect().width + 1;
        const width2 = currentText.textLines.reduce(
          (max, _, i) => Math.max(max, currentText.getLineWidth(i)),
          0
        );
        currentText.set({
          scaleX: 1,
          scaleY: 1,
          width:
            currentText.textLines.length > 1
              ? width1
              : Math.max(width1, width2),
        });
        try {
          currentText.initDimensions();
          currentText.setCoords();
        } catch (e: any) {
          console.error('Exception: ' + e.toString());
        }
        break;
      default:
        currentObject.fill = currentVariant.currentObject?.backgroundColor;
        break;
    }

    canvas?.requestRenderAll();
    const svg = canvas?.toSVG();
    updateCurrentVariant('svg', svg);
  }, [currentVariant?.currentObject]);

  useEffect(() => {
    if (!activeTab && currentObject) setCurrentObject(undefined);
  }, [activeTab]);

  // remove logo from canvas
  const removeImage = (type: 'logo' | 'bg') => {
    const object = getCanvasObject(type);
    if (object) {
      canvas?.remove(object);
      canvas?.requestRenderAll();
      updateCurrentVariant('svg', canvas?.toSVG());
    }
  };

  // add image to canvas
  const addImage = (type: 'logo' | 'bg', path: string) => {
    if (!canvas) return;
    fabric.Image.fromURL(path, (img) => {
      img.set({ ...DEFAULT_FABRIC_PROPS });
      if (type === 'bg') {
        img.scaleToWidth(canvasWidth);
        img.scaleToHeight(canvasHeight);
      } else {
        const ratio = img.width! / img.height!;
        const scale =
          ratio < 1
            ? MAX_LOGO_WIDTH / img.width!
            : MAX_LOGO_HEIGHT / img.height!;
        img.scale(scale);
        img.set({
          top: 25,
          left: canvasWidth - 25 - img.width! * scale,
        });
      }
      canvas?.add(img);
      if (type === 'logo') canvas.bringToFront(img);
      if (type === 'bg') {
        cropImage();
        updateCurrentVariant(
          'previewImageElement',
          getCanvasObject('bg')!.toSVG()
        );
      }
      canvas?.requestRenderAll();
      updateCurrentVariant('svg', canvas?.toSVG());
    });
  };

  // on logo changes (path)
  useEffect(() => {
    if (!hasMounted) return;
    removeImage('logo');
    addImage('logo', currentVariant?.logo?.path as string);
  }, [currentVariant?.logo]);

  // on background image changes (path)
  useEffect(() => {
    if (!hasMounted) return;
    if (!canvas) return;
    const background = getCanvasObject('bg') as fabric.Image | null;
    if (!background) return;
    background.setSrc(changeCanvasImage as string, () => {
      canvas?.requestRenderAll();
      cropImage(); // crop the image
      updateCurrentVariant(
        'previewImageElement',
        getCanvasObject('bg')!.toSVG()
      );
    });
  }, [changeCanvasImage, hasMounted]);

  // on image crop
  useEffect(() => {
    if (!hasMounted || !canvas) return;
    const background = getCanvasObject('bg');
    if (!background || !crop) return;
    if (crop) {
      const scaleX = canvasWidth / (background.width! * (crop!.width / 100));
      const scaleY = canvasHeight / (background.height! * (crop!.height / 100));
      background.left = -(background.width! * (crop!.x / 100) * scaleX);
      background.top = -(background.height! * (crop!.y / 100) * scaleY);
      background.scaleX = scaleX;
      background.scaleY = scaleY;
    }
    canvas.requestRenderAll();
    updateCurrentVariant('previewImageElement', getCanvasObject('bg')!.toSVG());

    updateCurrentVariant('svg', canvas.toSVG(), false);
  }, [crop, hasMounted]);

  // Executed on first render & cleanup of states
  useLayoutEffect(() => {
    if (!svgCanvasRef.current || hasMounted || !currentVariant) return;
    if (!canvas) {
      const canvaz = new fabric.Canvas(svgCanvasRef.current, {
        selection: true,
        preserveObjectStacking: true,
      });
      setCanvas(canvaz);
      initAligningGuidelines(canvaz);
      return;
    }
    (async () => {
      if (currentVariant?.svg) {
        await readSVGImage(currentVariant.svg!);
        setHasMounted(true);
      } else {
        updateCurrentVariant('overlay', OVERLAY_OPTIONS.DEFAULT);
      }
    })();
    return () => {
      //update state when unmount component
      const svg =
        canvas.getObjects().length > 0 && currentVariant.path
          ? canvas!.toSVG()
          : null;

      setVariants((variants) =>
        variants.forEach((variant) => {
          if (variant.tempId === currentVariant?.tempId) {
            variant.svg = svg;
            variant.overlay =
              currentVariant.path === null ? undefined : variant.overlay;
            variant.crop = undefined;
          }
        })
      );
      canvas.clearContext(canvas.getContext());
      if (canvas && canvas.getObjects().length > 0) {
        canvas.clear();
        canvas.dispose();
      }
      setCanvas(null);
      setCurrentObject(undefined);
      setSvgStack(Stack<string>());
      setHasMounted(false);
    };
  }, [currentVariant?.tempId, canvas]); // currentVariant?.path

  // When changing overlay
  useEffect(() => {
    if (!canvas) return;

    const overlay = getOverlay(
      currentVariant?.overlay,
      currentVariant,
      currentVacancy?.brand,
      false
    );

    (async () => await readSVGImage(overlay, true))().then(() => {
      if (!currentVariant?.svg) {
        setHasMounted(true);
      }

      const svg = canvas.toSVG();
      updateCurrentVariant('svg', svg);
    });
  }, [currentVariant?.overlay]);

  const { videoGen } = useVideoGenContext();

  return (
    <>
      <AdImageCanvasWrapper
        placement={currentVariant?.placement}
        canvasHeight={canvasHeight}
        canvasWidth={canvasWidth}
      >
        <AdImageCanvas
          ref={svgCanvasRef}
          height={canvasHeight}
          width={canvasWidth}
          tabIndex={1212}
        />
        {!currentVariant?.path && (
          <div
            id="placeholder"
            tw="h-full w-full flex justify-center absolute z-10 top-0 left-0 bg-gray-200"
          >
            <div tw="my-auto h-fit items-center">
              <div
                onClick={() => {
                  setActiveTab(AdEditorSideBarTabs.VISUAL_OPTIONS);
                  setUploadMode(true);
                }}
                tw="flex flex-col items-center justify-center text-gray-500 font-semibold cursor-pointer"
              >
                <UploadSimple weight="bold" size={32} tw="my-2" />
                Click here to upload an image/video
              </div>
              <Divider dark />
              <div
                onClick={videoGen}
                tw="flex flex-col items-center mt-2 justify-center text-gray-500 font-semibold cursor-pointer"
              >
                <Sparkle weight="fill" size={32} tw="my-1" />
                Generate a video using AI (beta)
              </div>
            </div>
          </div>
        )}
      </AdImageCanvasWrapper>
    </>
  );
};

export const AdImageCanvasWrapper = styled.div<{
  placement?: AdPlacement;
  canvasHeight?: number;
  canvasWidth?: number;
}>`
  ${tw`w-full overflow-hidden relative`}

  ${css`
    *:not(#placeholder *) {
      width: 100% !important;
      height: 100% !important;
    }
  `}

  ${({ placement }) =>
    placement === AdPlacement.FEED
      ? tw`aspect-square`
      : placement === AdPlacement.STORIES
      ? tw`aspect-[9/16]`
      : undefined}
`;

const AdImageCanvas = styled.canvas(
  tw`w-full relative z-10 top-0 left-0 object-fill`
);

export default AdEditorStencilCanvas;
