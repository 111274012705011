import { InvoiceItem } from 'types/plans/types';

export enum Interval {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  THREE_QUARTERLY = 'THREE_QUARTERLY',
  YEARLY = 'YEARLY',
}

export enum Tier {
  LAUNCH = 'LAUNCH',
  SCALE = 'SCALE',
  LEAD = 'LEAD',
  ENTERPRISE = 'ENTERPRISE',
}

export const TIER_RANKS: { [key in Tier]: number } = {
  [Tier.LAUNCH]: 1,
  [Tier.SCALE]: 2,
  [Tier.LEAD]: 3,
  [Tier.ENTERPRISE]: 4,
};

export interface Subscription {
  id: number;
  customer_id?: string;
  plan: Plan;
  scheduled: ScheduledPlan;
  status: 'ACTIVE' | 'INACTIVE';
  is_cancelled: boolean;
  disable_cancel: boolean;
  interval: Interval;
  current_period: {
    start: Date;
    end: Date;
  };
  price: number;
  trial_ends: Date;
  is_trial: boolean;
  payment_method: 'CREDIT_CARD' | 'WIRE_TRANSFER';
  current_live_vacs: number;
  max_live_vacs: number;
}

export interface Plan {
  name: string;
  tier: Tier;
  prices?: PlanPrice[];
  features?: string[];
}

export interface ScheduledPlan {
  interval: Interval;
  plan: {
    tier: Tier;
    name: string;
  };
}

export interface PlanPrice {
  interval: Interval;
  amount: number;
  id: number;
}

export interface CampaignExtensionItems {
  commission_percentage: number;
  extension_items: {
    name: string;
    items: InvoiceItem[];
  }[];
}
