import EmptyState from 'components/empty-state/EmptyState';
import { Body } from 'components/typography/Typography';
import React, { useMemo } from 'react';
import { Check, X, Star } from '@phosphor-icons/react';
import tw, { styled } from 'twin.macro';
import { CandidateSurvey, CandidateSurveyDetail } from 'types/candidates/types';
import { isValidUrl } from 'utils/baseHelpers';

export interface CandidateInterviewProps {
  survey?: CandidateSurvey;
}

const CandidateInterview: React.FC<CandidateInterviewProps> = ({ survey }) => {
  const getQuestionAnswer = (question: CandidateSurveyDetail) => {
    if (isValidUrl(question.answer)) return getUrlAnswer(question.answer);

    switch (question.type) {
      case 'yes_no':
        return getBooleanAnswer(
          question.answer === 'true' ||
            question.answer === '1' ||
            question.answer === 'yes'
        );
      case 'rating':
        return getRatingAnswer(Number.parseInt(question.answer));
      default:
        return (
          <Body isLight>
            A - {question.answer.replace(new RegExp('<br>', 'g'), ', ')}
          </Body>
        );
    }
  };

  const removeTemplatingFromQuestion = (question: string) => {
    return question.replace(new RegExp(/{[^}]*}./gi), '... ');
  };

  const addHttpToWebsite = (website: string): string => {
    const httpRegex = /^(http:\/\/|https:\/\/)/;
    if (!httpRegex.test(website)) return 'http://' + website;

    // If it already starts with http:// or https://, return the string as is
    return website;
  };

  const getUrlAnswer = (answer: string) => {
    return (
      <Body isLight>
        A -
        <a
          href={addHttpToWebsite(answer)}
          target={'_blank'}
          rel={'noreferrer'}
          tw="text-indigo-600 underline cursor-pointer break-words"
        >
          {answer}
        </a>
      </Body>
    );
  };

  const getBooleanAnswer = (answer: boolean) => {
    return (
      <Body isLight isCenter isFlex>
        A -
        {answer ? (
          <Check weight="bold" size={20} tw="ml-1 text-green-400" />
        ) : (
          <X weight="bold" size={20} tw="ml-1 text-sympl" />
        )}
      </Body>
    );
  };

  const getRatingAnswer = (rating: number) => {
    const amountOfEmptyStars = 5 - rating;
    return (
      <Body isLight isCenter isFlex>
        A -
        {[...Array(rating)].map((_x, i) => (
          <Star weight="fill" key={i} tw="self-center" />
        ))}
        {[...Array(amountOfEmptyStars)].map((_x, i) => (
          <Star weight="bold" key={i} tw="self-center" />
        ))}
      </Body>
    );
  };

  const sortCustomQuestionsFirst = (
    surveyDetailOne: CandidateSurveyDetail,
    surveyDetailTwo: CandidateSurveyDetail
  ) => {
    return Number(surveyDetailTwo.custom) - Number(surveyDetailOne.custom);
  };

  /** Filters out the data that is already present on the page */
  const filterRedundantData = (
    candidateSurveyDetails: CandidateSurveyDetail[]
  ) => {
    return candidateSurveyDetails.filter((question) => {
      const redundantData = [
        'first_name',
        'lastname',
        'email',
        'mobile_number',
        'cv_ready',
        'cv_upload',
        'sympl_privacy',
      ];

      return !redundantData.includes(question.reference);
    });
  };

  const sortedCandidateSurveyDetail = useMemo(() => {
    const newArray = survey?.candidateSurveyDetails
      ? [...survey?.candidateSurveyDetails]
      : [];
    return filterRedundantData(newArray).sort(sortCustomQuestionsFirst);
  }, [survey?.candidateSurveyDetails]);

  return (
    <>
      {survey ? (
        <div tw="mt-0 md:mt-4 h-full">
          <ul tw="h-full overflow-y-auto no-scrollbar">
            {sortedCandidateSurveyDetail.map(
              (question: CandidateSurveyDetail) => (
                <ConditionalWrapper
                  key={question.question}
                  condition={question.custom}
                  wrapper={(children) => (
                    <CustomQuestionWrapper>
                      {children}
                      <CustomTextWrapper>Custom question</CustomTextWrapper>
                    </CustomQuestionWrapper>
                  )}
                >
                  <QuestionWrapper key={question.question}>
                    <div tw="mb-1">
                      <Body>
                        Q - {removeTemplatingFromQuestion(question.question)}
                      </Body>
                    </div>
                    <div tw="text-sm md:text-base">
                      {getQuestionAnswer(question)}
                    </div>
                  </QuestionWrapper>
                </ConditionalWrapper>
              )
            )}
          </ul>
        </div>
      ) : (
        <EmptyState
          title={"The candidate hasn't filled in any surveys yet."}
          subtitle={
            'Whenever the candidate fills in a survey, they will show up here.'
          }
        />
      )}
    </>
  );
};

/** Places a component or React node inside a conditional wrapper */
interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: React.FC;
  children: any;
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children);

const QuestionWrapper = styled.li(
  tw`flex flex-col border-b border-gray-100 pb-4 pl-2 mb-2 pt-2 last-of-type:(m-0 border-none)`
);

const CustomQuestionWrapper = styled.div(
  tw`
   mb-2 border-b border-gray-100 grid-cols-2 relative flex justify-between
   last-of-type:(relative mb-5 border-none after:(content-[''] block w-full absolute h-0.5 -bottom-2 bg-gray-500))
  `
);

const CustomTextWrapper = styled.p(
  tw`text-gray-400 text-xs mb-1 place-self-end min-w-max`
);

export default CandidateInterview;
