import React, { useEffect, useMemo, useRef, useState } from 'react';

import 'twin.macro';
import AppPage from 'components/page/app-page/AppPage';
import SaveButton from 'components/save-button/SaveButton';
import VacancyPreviewer from 'components/previewers/VacancyPreviewer';
import useNavigationContext from 'hooks/context/nav-context';
import useVacancyContext from 'hooks/context/vacancy-context';
import { FeedbackType } from 'components/feedback/FeedbackWidget';
import { CopyToClipboard } from 'components/copy-to-clipboard/CopyToClipboard';
import { useLazyQuery, useQuery } from 'hooks/sympl-query';
import { TargetingConfiguration } from 'views/targeting/TargetingConfig';
import { GET_TARGETING_CONFIG } from 'graphql/vacancies/queries';
import { GET_JOBTYPES_SUBJOBTYPES } from 'graphql/jobtypes/queries';
import { EXPERIENCE, JobType } from 'types/targetingTypes';
import Button from 'components/button/Button';
import InputGroup from 'components/form/input-group/InputGroup';
import PillGroup from 'components/form/pill-group/PillGroup';
import { Modal } from 'components/page/app-page/Modal';
import { Title1, Title3 } from 'components/typography/Typography';
import { Controller, useForm } from 'react-hook-form';
import Input from 'components/form/input/Input';
import useApiList from 'hooks/useApiList';
import { isTextEditorEmpty, popConfetti, stripHtml } from 'utils/baseHelpers';
import useGetStartedContext from 'hooks/context/get-started-context';
import MissingFields from 'components/page/app-page/MissingFields';
import { generateVacancy } from 'utils/vacancy-generator/vacancyHelpers';
import { Robot, User } from '@phosphor-icons/react';
import { Routes } from 'types/routeTypes';
import { useSymplCookie } from 'hooks/symplCookie';
import { fireEvent } from 'utils/eventHelper';
import CustomUrlForm from '../custom-url/CustomUrlForm';
import CustomUrlPage from '../custom-url/CustomUrlPage';
import { useNavigate } from 'react-router-dom';

export type TEditorModalTypes =
  | 'custom-vacancy-url'
  | 'custom-vacancy-url-no-back'
  | 'first-time'
  | 'onboarding'
  | 'ai';

const EditorPage: React.FC = () => {
  const navigate = useNavigate();

  const [usedAiGen, setUsedAiGen] = useSymplCookie('usedAiGen');

  const {
    vacancyLoading,
    saveHandler,
    vacancyData,
    refetchCopy,
    setVacancyData,
    generatingFields,
    setGeneratingFields,
    setPayload,
  } = useVacancyContext();

  const {
    activeVacancy,
    vacIsBooster: isBooster,
    setHasUnsavedChanges,
    hasUnsavedChanges,
    brands,
  } = useNavigationContext();

  const { nextItem, refetchCheckList } = useGetStartedContext();

  const { control, handleSubmit } = useForm();

  const { data: languages } = useApiList('languages');

  const formData = useRef({
    companyDescription: '',
    companyOffers: ['', '', ''],
    toneOfVoice: [''],
  });
  const showedFirstTimeOnce = useRef(false);

  const [showModal, setShowModal] = useState<TEditorModalTypes>();

  const { data: targetingData, loading: loadingTargeting } = useQuery<
    TargetingConfiguration,
    { vacancyId: number }
  >(GET_TARGETING_CONFIG, {
    skip: !activeVacancy,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { vacancyId: activeVacancy ?? 0 },
  });

  const [fetchJobTypes] = useLazyQuery<{ jobTypes: JobType[] }, {}>(
    GET_JOBTYPES_SUBJOBTYPES
  );

  const checkField = (condition: boolean, message: string) =>
    condition ? [message] : [];

  const missingFields = useMemo(() => {
    if (!vacancyData) return [];

    return [
      ...checkField(!vacancyData.pictures?.logo, 'No logo set'),
      ...checkField(!vacancyData.pictures?.image_cb1, 'No first visual set'),
      ...checkField(!vacancyData.pictures?.image_cb3, 'No second visual set'),
      ...checkField(!vacancyData.title, 'No title set'),
      ...checkField(
        !vacancyData.vac_text_essence ||
          isTextEditorEmpty(vacancyData.vac_text_essence),
        'No essence text set'
      ),
      ...checkField(!vacancyData.todo_title, 'No todo title set'),
      ...checkField(
        !vacancyData.vac_text_todo ||
          isTextEditorEmpty(vacancyData.vac_text_todo),
        'No todo text set'
      ),
      ...checkField(
        !vacancyData.expectations_title,
        'No expectations title set'
      ),
      ...checkField(
        !vacancyData.vac_text_expectations ||
          isTextEditorEmpty(vacancyData.vac_text_expectations),
        'No expectations text set'
      ),
      ...checkField(!vacancyData.offer_title, 'No offer title set'),
      ...checkField(
        !vacancyData.vac_text_offer ||
          isTextEditorEmpty(vacancyData.vac_text_offer),
        'No offer text set'
      ),
      ...checkField(!vacancyData.company_title, 'No company title set'),
      ...checkField(
        !vacancyData.vac_text_company ||
          isTextEditorEmpty(vacancyData.vac_text_company),
        'No company text set'
      ),
    ];
  }, [vacancyData]);

  const validTargetingData = useMemo(() => {
    const targeting = targetingData?.targetingConfig.targeting;
    return (
      !loadingTargeting &&
      !!targeting?.vac_name &&
      !!targeting?.brand_id &&
      !!targeting?.locations &&
      !!targeting?.locations.length &&
      !!targeting?.job_type
    );
  }, [loadingTargeting, targetingData]);

  useEffect(() => {
    if (
      !vacancyData?.has_custom_url &&
      !vacancyData?.vac_url &&
      missingFields.filter((field) => field.includes('text')).length > 2 &&
      validTargetingData &&
      showedFirstTimeOnce.current === false &&
      generatingFields.length === 0
    ) {
      setShowModal('first-time');
      showedFirstTimeOnce.current = true;
    }
  }, [
    missingFields,
    targetingData,
    vacancyData,
    validTargetingData,
    showedFirstTimeOnce,
    generatingFields,
  ]);

  const onSubmit = async ({
    'company-description': companyDescription,
    'company-offers': offers,
    'tone-of-voice': toneOfVoice,
  }: {
    'company-description': string;
    'company-offers': string[];
    'tone-of-voice': string[];
  }) => {
    setShowModal(undefined);
    setGeneratingFields([
      'vac_text_essence',
      'vac_text_uniqueness',
      'vac_text_expectations',
      'vac_text_todo',
      'vac_text_offer',
      'vac_text_company',
    ]);

    // Persist state
    formData.current = {
      companyDescription,
      companyOffers: offers,
      toneOfVoice: toneOfVoice,
    };

    // Fetch data used for AI generation
    const { data: { jobTypes } = {} } = await fetchJobTypes();

    const targeting = targetingData?.targetingConfig.targeting;

    const jobType = jobTypes?.find(({ id }) => id === targeting?.job_type);

    const jobCategory = targeting?.sub_job_type
      ? jobType?.sub_job_types?.find(({ id }) => id === targeting?.sub_job_type)
          ?.name
      : jobType?.name;

    const payload = {
      jobFunction: targeting?.vac_name!,
      companyName: brands.find(({ id }) => id === targeting?.brand_id)?.name!,
      location: targeting?.locations?.[0].name!,
      jobCategory: jobCategory!,
      companyDescription,
      experienceLevel:
        targeting?.experience
          ?.map((code) => {
            return EXPERIENCE.find(({ code: c }) => c === code)?.name;
          })
          .join(', ') ?? 'None',
      toneOfVoice: toneOfVoice.join(', '),
      offers,
      language:
        languages.find(
          ({ key }) =>
            key === targetingData?.targetingConfig.targeting.language_id
        )?.label ?? 'English',
    };

    setPayload(payload);

    await generateVacancy({
      vacancyId: activeVacancy!,
      payload,
      setVacancyData,
      setGeneratingFields,
    });

    if (!usedAiGen) {
      popConfetti();
      setUsedAiGen(true);
    }

    fireEvent('ai_copy_popup');
  };

  const onCustomLinkSubmit = ({ isModal }: { isModal: boolean }) => {
    if (isModal) setShowModal(undefined);
    refetchCopy();
    refetchCheckList();
  };

  return (
    <AppPage
      heading="Vacancy Page"
      loading={vacancyLoading}
      enableFeedback={true}
      disablePadding={true}
      feedback={
        isBooster && vacancyData?.feedback_status
          ? {
              status: vacancyData?.feedback_status,
              type: FeedbackType.WEBSITE,
              previousFeedback: vacancyData?.previous_feedback ?? [],
              onSubmit: refetchCopy,
            }
          : undefined
      }
      clipboardCopy={
        vacancyData?.share_url && (
          <CopyToClipboard url={vacancyData?.share_url} />
        )
      }
      cta={
        <div tw="flex space-x-4">
          {!vacancyData?.has_custom_url && !!missingFields.length && (
            <MissingFields missingFields={missingFields} />
          )}
          {!hasUnsavedChanges && nextItem?.key === 'typeform' ? (
            <Button variant="indigo" onClick={() => navigate(Routes.INTERVIEW)}>
              Go to next step &rarr;
            </Button>
          ) : (
            <SaveButton
              shouldSave={hasUnsavedChanges}
              loading={vacancyLoading}
              disabled={!!generatingFields.length}
              onClick={() => saveHandler(vacancyData)}
              onDone={() => {
                setHasUnsavedChanges(false);
                refetchCheckList();
              }}
            />
          )}
        </div>
      }
    >
      <Modal
        show={showModal === 'first-time'}
        onClose={() => setShowModal(undefined)}
      >
        <Title1>Let's setup your vacancy page!</Title1>
        <div tw="grid grid-cols-2 gap-4 my-8">
          <button
            type="button"
            tw="p-8 ring-2 ring-gray-200 rounded-md grid items-center hover:(bg-blue-50 ring-blue-500) focus:outline-none"
            onClick={() => setShowModal(undefined)}
          >
            <div tw="space-y-4 text-gray-800">
              <User weight="fill" size={32} tw="m-auto" />
              <p tw="font-semibold">I'll create it myself.</p>
            </div>
          </button>
          <button
            type="button"
            tw="p-8 ring-2 ring-gray-200 rounded-md grid items-center hover:(bg-blue-50 ring-blue-500) focus:outline-none"
            onClick={() => setShowModal('ai')}
          >
            <div tw="space-y-4 text-gray-800">
              <Robot weight="fill" size={32} tw="m-auto" />
              <p tw="font-semibold">Create it for me (using AI).</p>
            </div>
          </button>
          <div tw="w-full text-center col-span-2 mt-2">
            <span
              tw="text-base font-normal text-gray-500 cursor-pointer text-center hover:(text-red-500 underline)"
              onClick={() => setShowModal('custom-vacancy-url')}
            >
              I want to use my own vacancy page
            </span>
          </div>
        </div>
      </Modal>
      <Modal show={showModal === 'ai'} onClose={() => setShowModal(undefined)}>
        <form tw="py-3 w-auto sm:w-[35rem]" onSubmit={handleSubmit(onSubmit)}>
          <div tw="flex flex-col space-y-5">
            <Controller
              id="company-description"
              name="company-description"
              control={control}
              defaultValue={
                stripHtml(vacancyData?.vac_text_company ?? '') ||
                formData.current.companyDescription
              }
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <div>
                  <Title3>Describe your company</Title3>
                  <Input
                    type="text"
                    rows={4}
                    textarea={true}
                    autoGrow={true}
                    value={value}
                    placeholder={
                      'e.g. SME, team of 15 people, founded in 2015, our product is ...'
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                </div>
              )}
            />

            <Controller
              id="company-offers"
              name="company-offers"
              control={control}
              defaultValue={
                vacancyData?.vac_text_uniqueness?.map((value) => value.value) ||
                formData.current.companyOffers
              }
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <div>
                  <Title3>
                    How would you convince people to apply for this job?
                  </Title3>
                  <InputGroup
                    id={'company-offers'}
                    value={value}
                    minInputs={3}
                    maxInputs={5}
                    onChange={onChange}
                  />
                </div>
              )}
            />

            <Controller
              id="tone-of-voice"
              name="tone-of-voice"
              control={control}
              defaultValue={formData.current.toneOfVoice}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <div>
                  <Title3>Which tone of voice is best suited?</Title3>
                  <PillGroup
                    selected={value}
                    options={[
                      '💼 Professional',
                      '😊 Friendly',
                      '😃 Enthusiastic',
                      '💪 Confident',
                      '📝 Formal',
                      '💬 Conversational',
                      '🗣 Assertive',
                      '😎 Excited',
                    ]}
                    onChange={(options) =>
                      onChange(
                        options
                          .filter(({ selected }) => selected)
                          ?.map(({ key }) => key)
                      )
                    }
                  />
                </div>
              )}
            />

            <div tw="ml-auto">
              <Button type="submit">Generate 🪄</Button>
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        show={
          showModal === 'custom-vacancy-url' ||
          showModal === 'custom-vacancy-url-no-back'
        }
        onClose={() => setShowModal(undefined)}
      >
        <CustomUrlForm
          onSubmit={() => onCustomLinkSubmit({ isModal: true })}
          showWarning
          {...(showModal === 'custom-vacancy-url' && {
            onBack: () => setShowModal('first-time'),
          })}
        />
      </Modal>
      {vacancyData?.has_custom_url ? (
        <CustomUrlPage
          onSubmit={() => onCustomLinkSubmit({ isModal: false })}
        />
      ) : (
        <VacancyPreviewer
          validTargetingData={validTargetingData}
          setShowModal={setShowModal}
        />
      )}
    </AppPage>
  );
};

export default EditorPage;
