import { AdPlacement } from 'types/adTypes';

export const getCanvasHeight = (placement: AdPlacement) => {
  switch (placement) {
    case AdPlacement.FEED:
      return 1000;
    case AdPlacement.STORIES:
    case AdPlacement.REELS:
      return 1920;
  }
};

export const getCanvasWidth = (placement: AdPlacement) => {
  switch (placement) {
    case AdPlacement.FEED:
      return 1000;
    case AdPlacement.STORIES:
    case AdPlacement.REELS:
      return 1080;
  }
};
