import 'twin.macro';
import React, { useMemo, useState } from 'react';
import {
  fileTypeToMimeType,
  lowercaseFileType,
  renameFile,
} from 'utils/fileHelpers';
import {
  DOCUMENT_MIME_TYPES,
  FileTypes,
  IMAGE_MIME_TYPES,
  SymplFileRejection,
  VIDEO_MIME_TYPES,
} from 'types/fileTypes';
import Dropzone, { DropzoneProps } from 'components/upload/dropzone/Dropzone';
import { CustomerResource } from 'types/apiTypes';
import useNavigationContext from 'hooks/context/nav-context';
import ResourcePicker from 'components/pickers/resource-picker/ResourcePicker';
import { arrayContainsValuesFromArray } from 'utils/baseHelpers';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';

import axios from 'axios';
import { AdChannel, AdCreativeType } from 'types/adTypes';
import { IResourcesFilterProps } from 'components/resources-modal/ResourcesModal';
import { FetchableResourceType } from '../pickers/resource-picker/ResourceTypes';

export interface BaseResourceUploadProps {
  /** Select all items when there are none (from defaultvalue) or select no items at all */
  emptySelectionStrategy?: 'select-all' | 'select-none';
  /** Gets called when the default value is initialized, returns the default selected resources (if any) */
  onLoad?: (selectedResources: CustomerResource[]) => void;
}

export interface ResourceUploadProps
  extends Omit<DropzoneProps, 'variant' | 'onUpload' | 'loading'>,
    BaseResourceUploadProps {
  style?: Object;
  completed?: boolean;
  defaultValue?: CustomerResource[] | number[];
  isLogo?: boolean;
  isAvatar?: boolean;
  resourcesFilter?: IResourcesFilterProps;
  channel?: AdChannel;
  creativeType?: AdCreativeType;
  enableToggle?: boolean;
  maxSelected?: number;
  onUploadStart?: () => void;
  onDelete?: (resourceIdx: number) => void;
  onDownload?: (resourceIdx: number) => void;
  onError?: (rejections: SymplFileRejection[]) => void;
  onChange?: (newResources: CustomerResource[]) => void;
}

type UploadableFileType = 'logo' | 'image' | 'avatar' | 'video' | 'document';

export interface UploadResponse {
  data: {
    data: CustomerResource;
  };
}

const ResourceUpload: React.FC<ResourceUploadProps> = ({
  id = 'resource-upload',
  name = id,
  style,
  defaultValue = [],
  completed,
  maxFiles,
  fileTypes = [],
  isAvatar = false,
  isLogo = false,
  channel,
  creativeType,
  compact = false,
  emptySelectionStrategy = 'select-none',
  resourcesFilter,
  enableToggle = true,
  maxSelected,
  onLoad,
  onChange,
  onError,
  onUploadStart,
}) => {
  const { activeCustomer } = useNavigationContext();
  const { addToast } = useToastNotifications();

  const [resources, setResources] = useState<CustomerResource[]>(
    typeof defaultValue?.[0] !== 'number'
      ? (defaultValue as CustomerResource[])
      : []
  );
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});

  const resourceTypes: FetchableResourceType = useMemo(() => {
    const mimeTypesFromFileTypes = fileTypes.map((type) =>
      fileTypeToMimeType(type as FileTypes)
    );

    const hasVideos = arrayContainsValuesFromArray(
      mimeTypesFromFileTypes,
      VIDEO_MIME_TYPES
    );
    const hasDocuments = arrayContainsValuesFromArray(
      mimeTypesFromFileTypes,
      DOCUMENT_MIME_TYPES
    );
    const hasImages = arrayContainsValuesFromArray(
      mimeTypesFromFileTypes,
      IMAGE_MIME_TYPES
    );

    if (isLogo && hasImages) return ['logo'];
    else if (isAvatar && hasImages) return ['avatar'];
    else if (hasImages && hasVideos) return ['image', 'video'];
    else if (hasImages) return ['image'];
    else if (hasVideos) return ['video'];
    else if (hasDocuments) return ['document'];
    else return ['logo', 'image', 'video', 'document'];
  }, [fileTypes, isLogo]);

  const isVideoFile = (file: File) => {
    return (VIDEO_MIME_TYPES as string[]).includes(file.type);
  };

  const isDocumentFile = (file: File) => {
    return (DOCUMENT_MIME_TYPES as string[]).includes(file.type);
  };

  const getUploadType = (upload: File): UploadableFileType => {
    const isVideo = isVideoFile(upload);
    const isDocument = isDocumentFile(upload);
    if (isLogo && !isVideo && !isDocument) return 'logo';
    else if (isAvatar) return 'avatar';
    else if (isVideo) return 'video';
    else if (isDocument) return 'document';
    else return 'image';
  };

  const uploadCustomerResource = async (
    payload: FormData
  ): Promise<UploadResponse> => {
    if (!activeCustomer) throw new Error('No active customer');

    return axios.post(
      `${import.meta.env.VITE_BASE_URL_API}/v4/customer-resources`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'active-customer': activeCustomer,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / (payload.get('file') as File).size
          );

          setUploadProgress((prev) => ({
            ...prev,
            [(payload.get('file') as File).name]: progress,
          }));
        },
      }
    );
  };

  const getAllUploadResults = (uploads: File[]) => {
    return Promise.all(
      uploads.map(async (upload) => {
        const payload = new FormData();

        payload.append('file', upload);
        payload.append('type', getUploadType(upload));
        payload.append(
          'customer_id',
          activeCustomer ? activeCustomer.toString() : 'null'
        );

        const {
          data: { data },
        } = await uploadCustomerResource(payload);
        return data;
      })
    );
  };

  const parseFile = (file: File) => {
    const parsed = lowercaseFileType(file);
    return renameFile(parsed, parsed.name.toLowerCase());
  };

  const resetDropzone = () => {
    setIsUploading(false);
    setUploadProgress({});
    setResources([]);
  };

  const uploadHandler = async (uploads: File[]) => {
    if (!activeCustomer) return;

    const parsedUploads = uploads.map((u) => parseFile(u));

    onUploadStart?.();

    try {
      // All uploads have to succeed in order to finish processing
      const uploadResults = await getAllUploadResults(parsedUploads);
      const createdResources = uploadResults.filter((resource) => !!resource);
      setResources(createdResources);
    } catch (error) {
      // incase there is an error.
      if (error) {
        const {
          response: { status },
        } = error as any;
        let description =
          'We were unable to upload your file. Please contact us if this problem persists!';

        if (status === 422) {
          description = (error as any).response.data.message;
        }
        addToast({
          title: 'Something went wrong',
          description,
          type: ToastTypes.ERROR,
        });
        resetDropzone();
      }

      onError?.([
        {
          errors: [
            {
              code: 'network',
            },
          ],
        },
      ]);
    } finally {
      setIsUploading(false);
      setUploadProgress({});
    }
  };

  const errorHandler = (rejections: SymplFileRejection[]) => {
    if (rejections.length) {
      addToast({
        title: 'Unable to upload file',
        description:
          rejections[0].errors[0].message ??
          'We were unable to upload your file.',
        type: ToastTypes.ERROR,
      });
      resetDropzone();
    }
    onError?.(rejections);
  };

  const selectHandler = (selectedResources: CustomerResource[]) =>
    onChange?.(selectedResources);

  return (
    <div
      tw="relative w-full bg-white p-4 border rounded-md shadow mb-4 min-h-[32vh]"
      style={style}
    >
      <ResourcePicker
        fileTypes={fileTypes}
        channel={channel}
        creativeType={creativeType}
        resourceTypes={resourceTypes}
        onSelect={selectHandler}
        onLoad={onLoad}
        emptySelectionStrategy={emptySelectionStrategy}
        uploadedResources={resources}
        defaultValue={defaultValue}
        maxSelected={maxSelected ?? maxFiles}
        resourcesFilter={resourcesFilter}
        enableToggle={enableToggle}
        addButton={
          <Dropzone
            id={id}
            name={name}
            maxFiles={maxFiles}
            loading={isUploading}
            uploadProgress={uploadProgress}
            compact={compact}
            onUpload={uploadHandler}
            onError={errorHandler}
            fileTypes={fileTypes}
            variant={!completed ? 'normal' : 'success'}
            onDrop={() => setIsUploading(true)}
          />
        }
      />
    </div>
  );
};

export default ResourceUpload;
