import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AdEditorAiPayload, AdVariant } from 'types/adEditorTypes';
import { AdChannel, AdCreativeType } from 'types/adTypes';
import { CustomerResource } from 'types/apiTypes';
import { Updater, useImmer } from 'use-immer';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import { v4 as uuid } from 'uuid';
import { Stack } from 'immutable';
import useNavigationContext from 'hooks/context/nav-context';

export type AdEditorContextType = {
  activeTab: AdEditorSideBarTabs | undefined;
  currentVariant: AdVariant | undefined;
  isAddingVariant: boolean;
  payload: AdEditorAiPayload | undefined;
  previewChannel: AdChannel | undefined;
  selectedChannels: AdChannel[];
  setActiveTab: Dispatch<SetStateAction<AdEditorSideBarTabs | undefined>>;
  setIsAddingVariant: Dispatch<SetStateAction<boolean>>;
  setPayload: Dispatch<SetStateAction<AdEditorAiPayload | undefined>>;
  setPreviewChannel: Dispatch<SetStateAction<AdChannel | undefined>>;
  setUploadMode: Dispatch<SetStateAction<boolean>>;
  setVariants: Updater<AdVariant[]>;
  updateCurrentVariant: (
    key: keyof AdVariant,
    value: any,
    setIsDirty?: boolean
  ) => void;
  uploadMode: boolean;
  variants: AdVariant[];
  videoRef: React.RefObject<HTMLVideoElement>;
  svgStack: Stack<string>;
  setSvgStack: Dispatch<SetStateAction<Stack<string>>>;
  newSvgElement: string;
  addNewSvgElement: Dispatch<SetStateAction<string>>;
  unsetNewSvgElement: () => void;
};

export const AdEditorContext = createContext<AdEditorContextType>(
  {} as AdEditorContextType
);

export const AdEditorProvider: React.FC<ReactNode> = ({ children }) => {
  const [activeTab, setActiveTab] = useState<AdEditorSideBarTabs>();
  const [payload, setPayload] = useState<AdEditorAiPayload>();
  const [previewChannel, setPreviewChannel] = useState<AdChannel>();
  const [variants, setVariants] = useImmer<AdVariant[]>([]);
  const [uploadMode, setUploadMode] = useState(false);
  const [isAddingVariant, setIsAddingVariant] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [svgStack, setSvgStack] = useState(Stack<string>());
  const [newSvgElement, setNewSvgElement] = useState<string>('');

  const { currentVacancy } = useNavigationContext();

  const currentVariant = useMemo(
    () => variants.find(({ isCurrent }) => isCurrent),
    [variants]
  );

  const unsetNewSvgElement = () => setNewSvgElement('');

  useEffect(() => {
    if (!variants.length || (currentVariant && !currentVariant.isDeleted))
      return;
    // If the current variant is deleted, remove the is current flag
    if (currentVariant?.isDeleted) {
      // Find the variant
      const variantIndex = variants.findIndex(
        ({ tempId }) => tempId === currentVariant.tempId
      );

      // Set is current to false
      if (variantIndex) variants[variantIndex].isCurrent = false;
    }

    setVariants((variants) => {
      variants[0].isCurrent = true;
    });
  }, [currentVariant, setVariants, variants]);

  // Generate temp id for new variants
  useEffect(() => {
    //update the state when generating an uuid
    setVariants((variants) => {
      variants.forEach((variant) => {
        if (!variant.tempId) variant.tempId = uuid();
      });
    });
  }, [variants, setVariants]);

  const selectedChannels = currentVacancy?.channels ?? [];

  // Set preview channel to first selected channel if not set
  useEffect(() => {
    if (previewChannel) return;

    const fallbackChannel = selectedChannels.length
      ? selectedChannels[0]
      : AdChannel.FACEBOOK;

    setPreviewChannel(fallbackChannel);
  }, [previewChannel, selectedChannels]);

  // Close side bar when switching variant
  useEffect(() => setActiveTab(undefined), [currentVariant?.id]);

  const updateCurrentVariant = (
    key: keyof AdVariant,
    value: any,
    setIsDirty: boolean = true
  ) => {
    setVariants((variants) => {
      const currentVariant = variants.find(({ isCurrent }) => isCurrent);
      const previousValue = currentVariant?.[key];

      if (!currentVariant) return;

      // Update the value
      (currentVariant[key] as any) = value;

      /**
       * Side effects
       */
      if (key === 'path') {
        if (!value) {
          currentVariant.creative_type = null;
          currentVariant.path = null;
        } else {
          const resourceType = (value as CustomerResource).type;

          currentVariant.creative_type = resourceType as
            | 'image'
            | 'video' as AdCreativeType;
          currentVariant.crop = null;
        }
      }

      if (!setIsDirty) return;
      // Don't set dirty flag when setting initial SVG or crop
      if (['svg', 'crop'].includes(key) && !previousValue) return;
      // Don't set dirty flag when setting previewImageElement
      if (['previewImageElement'].includes(key)) return;

      currentVariant.isDirty = true;
    });
  };

  return (
    <AdEditorContext.Provider
      value={{
        activeTab,
        currentVariant,
        isAddingVariant,
        payload,
        previewChannel,
        selectedChannels,
        setActiveTab,
        setIsAddingVariant,
        setPayload,
        setPreviewChannel,
        setUploadMode,
        setVariants,
        updateCurrentVariant,
        uploadMode,
        variants,
        videoRef,
        svgStack,
        setSvgStack,
        newSvgElement,
        addNewSvgElement: setNewSvgElement,
        unsetNewSvgElement,
      }}
    >
      {children}
    </AdEditorContext.Provider>
  );
};
