import tw, { styled } from 'twin.macro';
import React, { useState } from 'react';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import { AD_PLACEMENTS, AdCreativeType, AdPlacement } from 'types/adTypes';
import { getVariantIcon } from 'utils/adEditorHelpers';

import { v4 as uuid } from 'uuid';
import ToolTip from '../../tooltip/ToolTip';
import useNavigationContext from 'hooks/context/nav-context';
import { Warning, CaretDown, CaretUp, Plus, Copy } from '@phosphor-icons/react';
import { AdVariant } from 'types/adEditorTypes';
import { DUPLICATE_VARIANT } from 'graphql/ad-variants/mutations';
import { useMutation } from 'hooks/sympl-mutation';

export const AdEditorNavBar: React.FC<{
  id: string;
  func: string;
  location: string;
}> = ({ id, func, location }) => {
  const { setVariants, updateCurrentVariant, variants } = useAdEditorContext();

  const { currentVacancy } = useNavigationContext();

  const [showMobileNavBar, toggleMobileNavBar] = useState(false);

  const [duplicateAdVariant] = useMutation<
    { newVariant: AdVariant },
    { variantId: number }
  >(DUPLICATE_VARIANT);

  const addPreviewSubmitHandler = async (placement: AdPlacement) => {
    const referenceVariant =
      variants.find(({ placement: p }) => p === placement) ?? variants[0];

    if (!referenceVariant) return;

    updateCurrentVariant('isCurrent', false);

    const bannerTitle = currentVacancy?.brand?.default_banner_title ?? 'WANTED';
    // Create a new variant using the existing common properties
    setVariants((variants) => {
      variants.push({
        ...referenceVariant,
        id: undefined,
        path: null,
        ...(placement === AdPlacement.REELS && { logo: null }),
        ...([AdPlacement.STORIES, AdPlacement.REELS].includes(placement) && {
          description: '',
          ...(placement === AdPlacement.REELS && {
            text: `${bannerTitle}: ${func} in ${location}`,
          }),
        }),
        crop: null,
        has_ads: false,
        svg: null,
        tempId: uuid(),
        isCurrent: true,
        isDeleted: false,
        isDirty: true,
        placement,
        creative_type:
          placement === AdPlacement.REELS
            ? AdCreativeType.VIDEO
            : AdCreativeType.IMAGE,
      });
    });
  };

  const duplicateVariant = (variant: AdVariant) => {
    duplicateAdVariant({
      variables: {
        variantId: variant.id as number,
      },
    }).then((result) => {
      setVariants((variants) => [...variants, result.data?.newVariant]);
    });
  };

  const switchPreviewHandler = (tempId?: string) =>
    setVariants((variants) =>
      variants.forEach(
        (variant) => (variant.isCurrent = variant.tempId === tempId)
      )
    );

  const toggleHandler = () => {
    toggleMobileNavBar(!showMobileNavBar);
  };

  return (
    <AdVariantNavbarWrapper id={id}>
      <VariantOverview toggleNavbar={showMobileNavBar}>
        {AD_PLACEMENTS.map((placement) => (
          <div
            key={placement}
            tw="flex flex-col w-full h-fit max-h-full overflow-y-auto border-t relative lg:(border-t-0)"
            css={[placement === AdPlacement.FEED && tw`lg:border-t`]}
          >
            <div tw="lg:(sticky top-0) flex justify-between text-sm font-medium text-gray-500 items-center border-b bg-gray-50 w-full px-6 py-1">
              <p tw="uppercase">
                {placement}
                {variants.filter(
                  (p) => p.placement === placement && !p.isDeleted
                ).length > 4 && (
                  <div tw="relative left-1 top-0.5 inline-block ">
                    <ToolTip
                      text="We suggest using max. 4 variants."
                      placement="right"
                      arrow
                    >
                      <div>
                        <Warning weight="bold" size={17} />
                      </div>
                    </ToolTip>
                  </div>
                )}
              </p>
              <button
                tw="p-2 -mr-3 hover:text-black"
                type="button"
                onClick={() => addPreviewSubmitHandler(placement)}
              >
                <Plus weight="bold" size={20} />
              </button>
            </div>
            <div tw="overflow-y-auto h-[12.5rem]">
              {variants
                .filter(
                  (variant) =>
                    variant.placement === placement && !variant.isDeleted
                )
                .map((variant, index) => (
                  <VariantTabButton
                    className="group"
                    id={variant.tempId}
                    key={index}
                    isSelected={variant.isCurrent}
                    onClick={() => switchPreviewHandler(variant.tempId)}
                  >
                    <div tw="flex items-center py-4 w-full -ml-1.5">
                      <picture tw="[svg]:(h-10 w-10)">
                        {getVariantIcon(
                          variant.placement,
                          variant.creative_type ?? AdCreativeType.IMAGE
                        )}
                      </picture>
                      <p tw="font-medium text-gray-500 text-sm mx-2">
                        Variant {index + 1}
                      </p>
                      <ToolTip
                        placement="right"
                        text="Duplicate this variant"
                        arrow
                      >
                        <Copy
                          weight="bold"
                          tw="text-gray-400 invisible group-hover:(text-indigo-500 visible) cursor-pointer ml-auto"
                          size="20"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            duplicateVariant(variant);
                          }}
                        />
                      </ToolTip>
                    </div>
                  </VariantTabButton>
                ))}
            </div>
          </div>
        ))}
      </VariantOverview>
      <ToggleSelectNavButton onClick={toggleHandler}>
        {!showMobileNavBar ? (
          <CaretDown weight="bold" tw="mt-1" size={36} />
        ) : (
          <CaretUp weight="bold" tw="mt-1" size={36} />
        )}
      </ToggleSelectNavButton>
    </AdVariantNavbarWrapper>
  );
};

const AdVariantNavbarWrapper = styled.aside`
  ${tw`w-full relative left-0 z-20 bg-white h-auto -top-2 lg:(top-0 h-full (min-w-[150px] max-w-[200px])!)`}
  ${tw`flex flex-row border-t border-t-gray-200 shadow-md flex-wrap lg:(grid grid-rows-[auto 1fr] border-none border-r border-r-gray-200)`}
`;

const VariantOverview = styled.div<{ toggleNavbar?: boolean }>`
  ${tw`flex max-h-[20vh] w-full order-2 pb-2 lg:(pb-0 max-h-full overflow-y-hidden grid grid-rows-[repeat(3, minmax(auto, 100%))]) `}
  ${({ toggleNavbar }) => !toggleNavbar && tw`hidden`}
`;

const VariantTabButton = styled.button<{ isSelected: boolean }>`
  ${tw`grid w-full items-center px-2 md:px-5 min-w-[25%] cursor-pointer border-r border-r-gray-200
  lg:(flex flex-row place-items-center border-b border-b-gray-200 w-full shrink-0 grow-0 border-r-0)
  hover:bg-gray-100`}
  ${({ isSelected }) =>
    isSelected &&
    tw`bg-gray-100 border-l-[3px] border-blue-500 hover:bg-gray-200/90`}
`;

const ToggleSelectNavButton = styled.button`
  ${tw`flex justify-center text-gray-500 lg:(hidden flex-none order-none) flex-auto items-center order-1`}
`;

export default AdEditorNavBar;
