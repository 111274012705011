import AdEditorNavBar from 'components/ad-builder/ad-editor-preview-ad-variant-bar/Navbar';
import AdEditorPreview from 'components/ad-builder/Preview';
import AdEditorToolbar, {
  AdEditorButton,
  ToolbarOrientation,
} from 'components/ad-builder/Toolbar';
import Button from 'components/button/Button';
import Centered from 'components/centered/Centered';
import {
  Feedback,
  FeedbackStatus,
  FeedbackType,
} from 'components/feedback/FeedbackWidget';
import AppPage from 'components/page/app-page/AppPage';
import SaveButton from 'components/save-button/SaveButton';
import {
  DELETE_VARIANTS,
  UPDATE_EDITOR_DATA,
} from 'graphql/ad-variants/mutations';
import { GET_EDITOR_DATA } from 'graphql/vacancies/queries';
import useNavigationContext from 'hooks/context/nav-context';
import { useMutation } from 'hooks/sympl-mutation';
import { useLazyQuery, useQuery } from 'hooks/sympl-query';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { AdEditorSavablePreviewState, AdVariant } from 'types/adEditorTypes';
import {
  AdChannel,
  AdCreativeType,
  AdPlacement,
  VISUAL_AD_CHANNELS,
} from 'types/adTypes';

import VariantGenerator from './VariantGenerator';
import { TargetingConfiguration } from 'views/targeting/TargetingConfig';
import MissingFields from 'components/page/app-page/MissingFields';
import useGetStartedContext from 'hooks/context/get-started-context';
import useApiList from 'hooks/useApiList';
import { GET_JOBTYPES_SUBJOBTYPES } from 'graphql/jobtypes/queries';
import { EXPERIENCE, JobType } from 'types/targetingTypes';
import { Routes } from 'types/routeTypes';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import {
  getChannelIcon,
  getFormattedChannel,
  isVariantSupported,
} from 'utils/adEditorHelpers';
import ToolTip from 'components/tooltip/ToolTip';
import Sidebar from './Sidebar';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';
import { useNavigate } from 'react-router-dom';
import LogRocket from 'logrocket';

export interface AdEditorQueryResponse {
  editorData: {
    adFeedback?: {
      feedback_status: FeedbackStatus;
      previous_feedback: Feedback[];
    };
    adVariants: AdVariant[];
    targeting?: TargetingConfiguration['targetingConfig']['targeting'];
  };
}

interface AdEditorQueryPayload {
  vacancy: number;
}

export interface AdEditorDeleteVariantPayload {
  variantId: number;
  input: { vacancy_id: number };
}

export interface AdEditorUpdatePayload {
  input: { vacancy_id: number; adVariants: AdEditorSavablePreviewState[] };
}

export enum AdEditorSideBarTabs {
  VISUAL_OPTIONS = 'Visuals',
  OVERLAY_OPTIONS = 'Overlays',
  BANNER_OPTIONS = 'Banner Options',
  LOGO_OPTIONS = 'Logo',
  TEXT_OPTIONS = 'Text',
  SHAPE_OPTIONS = 'Shape',
  COLOR_OPTIONS = 'Color',
}

const AdEditor: React.FC = () => {
  const navigate = useNavigate();

  const { nextItem, refetchCheckList } = useGetStartedContext();
  const { addToast } = useToastNotifications();

  const {
    activeVacancy,
    vacIsBooster: isBooster,
    brands,
    currentVacancy,
  } = useNavigationContext();

  const {
    activeTab,
    currentVariant,
    previewChannel,
    selectedChannels,
    setActiveTab,
    setPayload,
    setPreviewChannel,
    setVariants,
    variants,
  } = useAdEditorContext();

  const [isSaving, setIsSaving] = useState(false);

  const firstTimeLoading = useRef(true);
  const editorInitialized = useRef(false);
  const editorVacancy = useRef<number>();

  const nonDeletedVariants = useMemo(
    () =>
      [...variants]
        .sort(
          // Sort variants based on placement
          (a, b) =>
            Object.values(AdPlacement).indexOf(a.placement) -
            Object.values(AdPlacement).indexOf(b.placement)
        )
        .filter(({ isDeleted }) => !isDeleted),
    [variants]
  );

  // Finds the index of the variant with the same placement type
  const findVariantIndex = (variant: AdVariant) => {
    // Find all variants of the same placement
    const variantsOfPlacement = nonDeletedVariants.filter(
      (v) => v.placement === variant.placement
    );

    // Find the index of the variant
    const variantIndex = variantsOfPlacement.findIndex(
      (v) => v.tempId === variant.tempId
    );

    return variantIndex;
  };

  const missingFields = useMemo(
    () => [
      ...(!nonDeletedVariants.find(
        ({ placement }) => placement === AdPlacement.FEED
      )
        ? ['No feed variant provided']
        : []),
      ...(!nonDeletedVariants.find(
        ({ placement }) => placement === AdPlacement.STORIES
      )
        ? ['No story variant provided']
        : []),
      ...nonDeletedVariants
        .filter((variant) => !variant.path)
        .map(
          (variant) =>
            `${
              'No' +
              ` ${variant.creative_type ?? 'visual'} ` +
              'set for' +
              ` ${variant.placement} variant ` +
              (findVariantIndex(variant) + 1)
            }`
        ),
      ...nonDeletedVariants
        .filter(
          (variant) =>
            !variant.text && variant.placement !== AdPlacement.STORIES
        )
        .map(
          (variant) =>
            `${
              'No ad text set for' +
              ` ${variant.placement} variant ` +
              (findVariantIndex(variant) + 1)
            }`
        ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nonDeletedVariants]
  );

  const {
    loading: loadingEditorData,
    data: editorResponse,
    refetch: refetchEditorData,
  } = useQuery<AdEditorQueryResponse, AdEditorQueryPayload>(GET_EDITOR_DATA, {
    skip: !activeVacancy,
    variables: { vacancy: activeVacancy! },
    fetchPolicy: 'network-only',
  });

  const previousFeedback =
    editorResponse?.editorData?.adFeedback?.previous_feedback;

  const feedbackStatus =
    editorResponse?.editorData?.adFeedback?.feedback_status;

  const hasUnsavedChanges = useMemo(
    () => !!variants.find(({ isDirty, isDeleted }) => isDirty || isDeleted),
    [variants]
  );

  useEffect(() => {
    const currentIndex = variants.findIndex(({ isCurrent }) => isCurrent);
    firstTimeLoading.current = false;

    setVariants(
      editorResponse?.editorData.adVariants.map((variant, index) => ({
        ...variant,
        isDirty: false,
        isDeleted: false,
        company: variant.company ?? currentVacancy?.brand?.name,
        overlay: variant.overlay,
        isCurrent: currentIndex !== -1 && index === currentIndex,
      })) ?? []
    );
    if (activeVacancy) refetchCheckList();
  }, [editorResponse?.editorData]);

  const [
    updateVariants,
    { loading: updatingVariants, error: updateVariantsError },
  ] = useMutation<{}, AdEditorUpdatePayload>(UPDATE_EDITOR_DATA);

  const [
    deleteVariants,
    { loading: deletingVariants, error: deletingVariantsError },
  ] = useMutation<{}, AdEditorDeleteVariantPayload>(DELETE_VARIANTS);

  const updateAdHandler = async (): Promise<void> => {
    if (!isSaving) {
      setIsSaving(true);
      try {
        // Filter only dirty, non-deleted variants
        const dirtyVariants = nonDeletedVariants.filter(
          ({ isDirty }) => isDirty
        );

        const variantsToDelete = variants
          .filter(({ isDeleted }) => isDeleted)
          .filter(({ id }) => id);

        if (dirtyVariants.length) await updateVariantsHandler(dirtyVariants);

        if (variantsToDelete.length)
          await Promise.all(
            variantsToDelete.map(({ id }) => deleteVariantsHandler(id!))
          );

        await refetchEditorData();
        await refetchCheckList();
      } catch (_) {
      } finally {
        setIsSaving(false);
      }
    }
  };

  const updateVariantsHandler = async (
    dirtyVariants: AdVariant[]
  ): Promise<void> => {
    try {
      const savableVariants = dirtyVariants.map((v) => ({
        ...v,
        creative_type:
          v.creative_type ??
          (v.placement === AdPlacement.REELS
            ? AdCreativeType.VIDEO
            : AdCreativeType.IMAGE),
        path_id: v.path?.id ?? null,
        logo_id: v.logo?.id ?? null,

        //DUMMY DATA
        logo_position: 'northeast',
        banner: true,
        banner_position: 'south',
        banner_title: currentVacancy?.brand?.default_banner_title ?? 'WANTED',
        banner_font_family: 'Arial',
        banner_font_size: '55',
      }));

      const { errors } = await updateVariants({
        variables: {
          input: {
            vacancy_id: activeVacancy as number,
            adVariants: savableVariants,
          },
        },
      });
      if (!errors)
        setVariants((variants) =>
          variants.forEach((variant) => (variant.isDirty = false))
        );

      addToast({
        type: errors ? ToastTypes.ERROR : ToastTypes.SUCCESS,
        description: errors
          ? 'An error occurred while updating the ad variants'
          : 'Your ads have been successfully updated',
      });
      return errors ? Promise.reject() : Promise.resolve();
    } catch (_) {
      addToast({
        type: ToastTypes.ERROR,
        description: 'An error occurred while updating the ad variants',
      });
      return Promise.reject();
    }
  };

  const deleteVariantsHandler = async (variantId: number): Promise<void> => {
    try {
      const { errors } = await deleteVariants({
        variables: {
          variantId,
          input: {
            vacancy_id: activeVacancy as number,
          },
        },
      });

      addToast({
        type: errors ? ToastTypes.ERROR : ToastTypes.SUCCESS,
        description: errors
          ? 'An error occurred while deleting the ad variants'
          : 'Your ads have been successfully deleted',
      });

      return errors ? Promise.reject() : Promise.resolve();
    } catch (_) {
      return Promise.reject();
    }
  };

  const refetchVariants = () =>
    new Promise<void>(async (resolve) => {
      const {
        data: {
          editorData: { adVariants },
        },
      } = await refetchEditorData();

      setVariants(
        adVariants.map((variant) => ({
          ...variant,
          isDirty: false,
          isDeleted: false,
          company: variant.company ?? currentVacancy?.brand?.name,
        }))
      );
      editorInitialized.current = true;
      editorVacancy.current = activeVacancy ?? undefined;

      if (firstTimeLoading) firstTimeLoading.current = false;

      resolve();
    });

  useEffect(() => {
    if (activeVacancy !== editorVacancy.current)
      editorInitialized.current = false;

    (async () => {
      if (
        editorResponse?.editorData &&
        !firstTimeLoading &&
        !editorInitialized.current &&
        activeVacancy
      ) {
        const {
          data: {
            editorData: { adVariants },
          },
        } = await refetchEditorData();

        setVariants(
          adVariants.map((variant) => ({
            ...variant,
            isDirty: false,
            isDeleted: false,
            company: variant.company ?? currentVacancy?.brand?.name,
          }))
        );

        editorInitialized.current = true;
        editorVacancy.current = activeVacancy;
      }
    })();
  }, [
    activeVacancy,
    editorResponse?.editorData,
    loadingEditorData,
    firstTimeLoading,
    refetchEditorData,
    refetchCheckList,
    setVariants,
  ]);

  const showVariantGenerator = useMemo(
    () => !nonDeletedVariants.length,
    [nonDeletedVariants]
  );

  const { data: languages } = useApiList('languages');

  const [getJobTypes] = useLazyQuery<{ jobTypes: JobType[] }>(
    GET_JOBTYPES_SUBJOBTYPES,
    {
      fetchPolicy: 'network-only',
      skip: !activeVacancy,
    }
  );

  useEffect(() => {
    if (import.meta.env.PROD) {
      LogRocket.init('2ky20e/sympl-customer-intake');
    }
  });

  useEffect(() => {
    // No need to fetch other data if we can't generate payload anyways
    if (!editorResponse?.editorData.targeting) return;

    (async () => {
      const jobTypes = await getJobTypes();

      const targetingData = editorResponse?.editorData.targeting;
      const brandsData = brands;
      const jobTypesData = jobTypes?.data?.jobTypes;
      const jobType = jobTypesData?.find(
        (jobType) => jobType.id === targetingData?.job_type
      );
      const jobCategory = targetingData?.sub_job_type
        ? jobType?.sub_job_types?.find(
            (subJobType) => subJobType.id === targetingData?.sub_job_type
          )?.name
        : jobType?.name;
      const language =
        languages.find(
          (language) => language.key === targetingData?.language_id
        )?.label ?? 'Dutch';

      setPayload({
        jobFunction: targetingData?.vac_name ?? '',
        companyName:
          brandsData?.find(({ id }) => id === targetingData?.brand_id)?.name ??
          '',
        location: targetingData?.locations?.[0]?.name ?? 'Antwerp',
        jobCategory: jobCategory ?? '',
        experienceLevel:
          targetingData?.experience
            ?.map((code) => {
              return EXPERIENCE.find(({ code: c }) => c === code)?.name;
            })
            .join(', ') ?? 'None',
        language,
      });
    })();
  }, [
    editorResponse?.editorData.targeting,
    getJobTypes,
    languages,
    setPayload,
  ]);

  const checkVariantSupported = (channel: AdChannel): boolean =>
    !!currentVariant &&
    isVariantSupported(
      currentVariant.placement,
      channel,
      currentVariant.creative_type ??
        (currentVariant.placement === AdPlacement.REELS ||
          channel === AdChannel.TIKTOK)
        ? AdCreativeType.VIDEO
        : AdCreativeType.IMAGE
    );

  const displaySaveBttn = () => {
    return (
      !showVariantGenerator && (
        <SaveButton
          loading={updatingVariants}
          shouldSave={hasUnsavedChanges}
          disabled={
            updatingVariants ||
            deletingVariants ||
            !hasUnsavedChanges ||
            !selectedChannels.length ||
            missingFields.length > 0
          }
          error={updateVariantsError || deletingVariantsError}
          onClick={updateAdHandler}
        />
      )
    );
  };

  return (
    <AppPage
      heading="Ad Builder"
      disablePadding={true}
      loading={loadingEditorData || updatingVariants || deletingVariants}
      enableFeedback={true}
      feedback={
        isBooster && feedbackStatus
          ? {
              status: feedbackStatus,
              type: FeedbackType.ADVERTISING,
              previousFeedback: previousFeedback ?? [],
              onSubmit: refetchEditorData,
            }
          : undefined
      }
      cta={
        <div tw="flex space-x-5">
          {!!(nonDeletedVariants.length && missingFields.length) && (
            <MissingFields missingFields={missingFields} />
          )}
          {!hasUnsavedChanges && nextItem?.key === 'vacancy_page' ? (
            <Button
              variant="indigo"
              onClick={() => navigate(Routes.JOB_POSTING)}
            >
              Go to next step &rarr;
            </Button>
          ) : (
            displaySaveBttn()
          )}
        </div>
      }
      isSideBarOpen={!!activeTab}
      setActiveTab={setActiveTab}
      sideBarMenu={[
        AdEditorSideBarTabs.VISUAL_OPTIONS,
        AdEditorSideBarTabs.OVERLAY_OPTIONS,
      ]}
      activeTabIndex={
        Object.values(AdEditorSideBarTabs).indexOf(
          activeTab || AdEditorSideBarTabs.VISUAL_OPTIONS
        ) ?? 0
      }
      sideBarContent={!!activeTab && <Sidebar />}
    >
      <div
        css={[
          tw`flex flex-col h-full lg:flex-row`,
          !showVariantGenerator ? tw`relative` : tw`py-20 bg-gray-50`,
        ]}
      >
        {!showVariantGenerator && (
          <AdEditorNavBar
            id="editor-navbar"
            func={currentVacancy?.title ?? '...'}
            location={
              (editorResponse?.editorData.targeting?.locations?.length
                ? editorResponse?.editorData.targeting?.locations?.[0].name
                : '...') ?? '...'
            }
          />
        )}
        <div tw="w-full h-full overflow-y-auto">
          <Centered id="container" tw="h-auto">
            {showVariantGenerator ? (
              <VariantGenerator refetchEditorData={refetchVariants} />
            ) : (
              <EditorWrapper id="editor-wrapper" activeTab={activeTab}>
                <PreviewContainer>
                  <div>
                    <div tw="mb-4">
                      <AdEditorToolbar
                        orientation={ToolbarOrientation.HORIZONTAL}
                        divide={false}
                        customButtons={VISUAL_AD_CHANNELS.map((channel) => (
                          <ToolTip
                            text={
                              !checkVariantSupported(channel)
                                ? `${getFormattedChannel(
                                    channel
                                  )} does not support ${
                                    currentVariant?.placement
                                  } ads`
                                : !selectedChannels.includes(channel)
                                ? 'This channel is not enabled.'
                                : 'Show a preview for' +
                                  ' ' +
                                  getFormattedChannel(channel)
                            }
                          >
                            <AdEditorButton
                              isActive={previewChannel === channel}
                              isDisabled={
                                !selectedChannels.includes(channel) ||
                                !checkVariantSupported(channel)
                              }
                              onClick={() => {
                                if (!checkVariantSupported(channel)) return;

                                setPreviewChannel(channel);
                              }}
                            >
                              <div tw="[svg]:p-1">
                                {getChannelIcon(channel, 32)}
                              </div>
                            </AdEditorButton>
                          </ToolTip>
                        ))}
                      />
                    </div>

                    <AdEditorPreview
                      id="editor"
                      functionTitle={
                        currentVariant?.function || currentVacancy?.title
                      }
                    />
                  </div>
                </PreviewContainer>
              </EditorWrapper>
            )}
          </Centered>
        </div>
      </div>
    </AppPage>
  );
};

const EditorWrapper = styled.div<{ activeTab?: AdEditorSideBarTabs }>`
${tw`relative h-full pt-4 pb-16 lg:pb-1 lg:grid`}
${({ activeTab }) => activeTab && tw`hidden`}
}
`;

const PreviewContainer = styled.div(
  tw`relative pb-5 flex flex-col-reverse items-center w-full lg:(block p-0 m-0) lg:w-full`
);

export default AdEditor;
