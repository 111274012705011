export interface JobType {
  id: number;
  name: string;
  sub_job_types: SubJobType[];
}

interface SubJobType {
  id: number;
  name: string;
}

export const EXPERIENCE = [
  {
    code: 'exp_none',
    name: 'None',
  },
  {
    code: 'exp_2',
    name: '0-2 years',
  },
  {
    code: 'exp_5',
    name: '3-5 years',
  },
  {
    code: 'exp_10',
    name: '6-10 years',
  },
  {
    code: 'exp_10+',
    name: '10+ years',
  },
];
