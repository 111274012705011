import { gql } from '@apollo/client';

export const GET_VACANCY = gql`
  query getVacancy($id: ID!) {
    vacancy(id: $id, include: $include)
      @rest(
        type: "Vacancy"
        path: "/v4/vacancies/{args.id}?include={args.include}"
      ) {
      id
      key
      title
      label
      published
      name
      status
      spend_status
      customer_id
      vacancy_url
      company
      is_booster
      budget
      customer_type
      created_at
      has_custom_url
      language_id
      typeform_url
      brand_id
      location
      channels
      templates
      has_custom_url
      lead_recruiter {
        id
        firstname
      }
      brand {
        id
        name
        slug
        default_primary_color
        default_secondary_color
        default_tertiary_color
        default_recruiter
        default_language_id
        default_logo
        default_creatives
        default_locations
        default_facebook_page_id
        default_display_link
        default_banner_title
        facebookPage {
          id
          customer_id
          channel_id
          ig_channel_id
          name
          logo_url
          status
        }
      }
    }
  }
`;

export const GET_VACANCY_ADS = gql`
  query getVacancyAds($vacancyId: ID!) {
    ads(id: $vacancyId)
      @rest(
        type: "[Ad]"
        path: "/v4/vacancies/{args.id}/ads?include=adMetric"
      ) {
      id
      priority
      channel
      name
      status
      currency
      stencil
      file_type
      thumbnail_url
      placement
      adMetric @type(name: "AdMetric") {
        applications
        impressions
        spend
        visits
        leads
        apmi
        convs3075
        clicks
      }
    }
  }
`;

export const GET_VACANCY_METRICS = gql`
  query getVacancyMetrics($vacancyId: ID!, $days: Int, $channel: String) {
    metrics(id: $vacancyId, days: $days, channel: $channel)
      @rest(
        type: "VacancyMetric"
        path: "/v4/vacancies/{args.id}?include=vacMetrics,vacBudget,vacSpent,vacApplications&filter_days={args.days}&filter_channel={args.channel}"
      ) {
      status
      spend_status
      vacBudget {
        total_budget
      }
      vacSpent
      vacApplications
      vacMetrics @type(name: "[Metrics]") {
        applications
        date
        impressions
        visits
        leads
        spend
        clicks
      }
    }
  }
`;

export const GET_VACANCY_BUDGET = gql`
  query getVacancyBudget($vacancyId: ID!) {
    budget(id: $vacancyId)
      @rest(
        type: "VacancyBudget"
        path: "/v4/vacancies/{args.id}?include=vacBudget"
      ) {
      vacBudget {
        total_budget
      }
    }
  }
`;

export const GET_EDITOR_DATA = gql`
  query getEditorData($vacancy: ID!) {
    editorData(vacancy: $vacancy)
      @rest(
        type: "AdEditorData"
        path: "/v4/vacancies/{args.vacancy}?include=adVariants,adFeedback,targeting"
      ) {
      adFeedback
      adVariants {
        id
        company
        description
        headline
        display_link
        text
        has_ads
        path
        logo
        function
        cta
        placement
        creative_type
        svg
      }
      targeting {
        brand_id
        job_type
        sub_job_type
        experience
        vac_name
        locations
        language_id
        locations {
          name
        }
        brand {
          id
          name
          slug
          default_primary_color
          default_secondary_color
          default_tertiary_color
          default_recruiter
          default_language_id
          default_logo
          default_creatives
          default_locations
          default_facebook_page_id
          default_display_link
          default_banner_title
        }
      }
    }
  }
`;

export const GET_VACANCY_CHECKLIST = gql`
  query getChecklist($id: ID!) {
    checklist(id: $id)
      @rest(
        type: "VacancyChecklistItem"
        path: "/v4/vacancies/{args.id}/checklist"
      ) {
      key
      label
      completed
      feedback_status
      optional
    }
  }
`;

export const GET_TARGETING_CONFIG = gql`
  query getTargetingConfig($vacancyId: ID!) {
    targetingConfig(vacancy: $vacancyId)
      @rest(
        type: "TargetingConfiguration"
        path: "/v4/vacancies/{args.vacancy}?include=targeting"
      ) {
      published
      targeting {
        job_type
        sub_job_type
        experience
        vac_name
        locations
        language_id
        brand_id
      }
    }
  }
`;

export const GET_PREFERENCES_CONFIG = gql`
  query getPreferencesConfig($vacancyId: ID!) {
    preferencesConfig(vacancy: $vacancyId)
      @rest(
        type: "PreferencesConfiguration"
        path: "/v4/vacancies/{args.vacancy}?include=notificationPreferences,vacancyTemplates,integrationEntities"
      ) {
      name
      lead_recruiter_id
      excluded_from_careers_page
      published
      notificationPreferences {
        email_on_candidate
        email_on_communication
      }
      vacancyTemplates {
        enabled
        done_survey
        ask_for_cv
        got_cv
      }
      integrationEntities {
        id
        external_id
        integration {
          type
          friendly_name
          logo_url
          icon_url
        }
        job_info {
          id
          name
          status
          job_code
        }
      }
    }
  }
`;

export const GET_VACANCY_COPY = gql`
  query getVacancyCopy($vacancyId: ID!) {
    vacancy(id: $vacancyId)
      @rest(
        type: "VacancyWithCopy"
        path: "/v4/vacancies/{args.id}?include=vacCopy"
      ) {
      published
      has_custom_url
      brand_id
      vacCopy @type(name: "VacancyCopy") {
        previous_feedback
        feedback_status
        primary_color
        secondary_color
        tertiary_color
        company_title
        expectations_title
        id
        pictures
        job_title
        offer_title
        page_cta
        procedure_text
        procedure_title
        title
        todo_title
        vac_text_company
        vac_text_essence
        vac_text_expectations
        vac_text_offer
        vac_text_todo
        vac_text_uniqueness @type(name: "[VacancyCopyUniqueness]") {
          value
          icon
        }
        vac_url
        share_url
        url
      }
    }
  }
`;

export const GET_VACANCY_SURVEY = gql`
  query getVacancySurvey($vacancyId: ID!) {
    vacancy(id: $vacancyId, days: $days, channel: $channel)
      @rest(
        type: "[Vacancy]"
        path: "/v4/vacancies/{args.id}?include=vacSurvey"
      ) {
      typeform_url
      published
      vacSurvey {
        id
        logo_id
        primary_color
        secondary_color
        questions @type(name: "[FormQuestion]") {
          channel_extra
          custom
          editable
          index
          label
          properties
          ref
          required
          type
        }
      }
    }
  }
`;

export const GET_VACANCY_TRAJECTORY_PACE = gql`
  query getPace($vacancyId: ID!) {
    getPace(id: $vacancyId)
      @rest(path: "/v4/vacancies/{args.id}/pace", method: "GET") {
      pace
    }
  }
`;
