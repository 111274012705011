import { ChatGPTMessage } from 'utils/openAiHelpers';

const LIST_OF_JOBS = `
  Administration: (Office Manager, Administrative Assistant),
  Architecture: (Interior Architect, Exterior Architect),
  Automotive: (Driver, Car Mechanic, Forklift Driver, Truck Driver),
  Beauty: (Hairdresser),
  Climate: (Sustainability),
  Commercial: (Business Developer, Sales Representative, Account Manager),
  Communication: (Communication Responsible, Public Relations Responsible),
  Construction: (Painter, Construction Worker, Carpenter, Project Lead),
  Consultancy: (Project Manager, Product Manager, Consultant, Innovation Consultant),
  Customer Success: (Customer Service, Customer Support, Calling Agent),
  Data: (Business Analyst),
  Education: (Teacher),
  Engineering: (Engineer),
  Finance: (Economics, Accountancy),
  Gardening: (Gardener),
  Graphics: (Graphic Designer),
  Healthcare: (Caregiver, Nurse, Social Worker),
  Hospitality: (Tourism, Cook, Bartender),
  Human Resources: (Human Resources, Recruiter),
  Insurance: (Insurance Officer),
  IT: (Fullstack Developer, Data Manager, E-Commerce Specialist, Frontend Developer, Backend Developer),
  Lab: (Lab Researcher),
  Legal: (Lawyer),
  Logistics: (Supply Chain, Logistics),
  Manufacturing: (Process Operator, Machine Operator),
  Marketing: (Digital Marketeer, Product Marketeer, Marketeer),
  Prevention: (Prevention Advisor),
  Production: (Production Employee, Production Manager, Warehouse Worker),
  Purchase: (Buyer),
  Real Estate: (Syndic),
  Retail: (Shop Assistant, Cashier),
  Technician: (Welder, Electrician, Maintenance Technician),
  Other.
`;

export const generatePromptFunctionJobType = (
  jobFunction: string
): ChatGPTMessage => ({
  role: 'user',
  content:
    `Identify the single most relevant category & subJob type for the function title '${jobFunction}'. If it doesnt exist from the list pick 'Other'. Use the provided categories and respond in the following Raw JSON format: { "category": "", "subJob": "" }. Don't use markdown syntax. Categories: ${LIST_OF_JOBS}`
      .replace(/\s+/g, ' ')
      .trim(),
});
